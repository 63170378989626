import {Box} from "@mui/material"
import {makeStyles} from "@mui/styles"
import {useMemo, useState} from "react"
import cn from "classnames"
import {Outlet, useParams} from "react-router-dom"
import Header from "components/Header"
import Sidebar from "components/Sidebar"
import Footer from "components/Footer"
import {useSelector} from "store"
import {sidebarWidth} from "consts"

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    width: "100%",
    height: "auto",
    backgroundColor: theme.palette.background.paper
  },
  layout: {
    position: "relative",
    // width: "100%",
    height: "100%"
    // paddingTop: "64px",
    // paddingBottom: "48px"
  },
  contentOnly: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 0
  },
  content: {
    position: "relative",
    width: "100%",
    height: "100%"
    // overflow: "auto",
    // WebkitOverflowScrolling: "touch"
  }
}))

export default function DashboardLayout() {
  const s = useStyles()
  const params = useParams()

  const schoolId = useSelector(({schoolSlice}) => schoolSlice.currentSchool)

  const [showMobileSidebar, setShowMobileSidebar] = useState(false)

  const checkExamWindowPage = useMemo(() => {
    return !!((params.examCode && params.sessionId) || params.labSessionId)
  }, [params])

  return (
    <Box
      key={schoolId}
      className={s.root}
      sx={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between"
      }}>
      {!checkExamWindowPage && (
        <Header onSidebarMobileOpen={() => setShowMobileSidebar(true)}/>
      )}
      <Box display="flex" height="100%" flexGrow={1} flexBasis={0} overflow={checkExamWindowPage ? "hidden" : undefined}>
        {!checkExamWindowPage && (
          <Sidebar
            onMobileClose={() => setShowMobileSidebar(false)}
            openMobile={showMobileSidebar}
          />
        )}
        <Box
          className={cn(s.layout, {[s.contentOnly]: checkExamWindowPage})}
          sx={{
            width: checkExamWindowPage ? "100%" : {xs: "100%", lg: `calc(100% - ${sidebarWidth}px)`}
          }}>
          <Box className={s.content}>
            <Outlet/>
          </Box>
        </Box>
      </Box>
      {!checkExamWindowPage && <Footer/>}
    </Box>
  )
}
