import {Box} from "@mui/material"
import type {MDEditorProps} from "@uiw/react-md-editor/lib/Types"
import React, {useMemo, useRef, useState} from "react"
import MDEditor, {commands} from "@uiw/react-md-editor"

interface Props {
  value: string
  fontFamily?: string
  onChange?: (value: string) => void
  onImageUpload?: (file: File) => Promise<string>
  onError?: (error: any) => void
  fullWidth?: boolean
  inactive?: boolean
}

export default function MD({
  value,
  fontFamily,
  onChange,
  onImageUpload,
  onError,
  fullWidth,
  inactive
}: Props) {
  const [editorApi, setEditorApi] = useState<any>(null)

  const fileInputRef = useRef<HTMLInputElement>(null)

  const editable = useMemo(() => {
    return !!onChange
  }, [onChange])

  const config: MDEditorProps = useMemo(() => {
    const commandsList = [
      commands.bold,
      commands.italic,
      commands.strikethrough,
      commands.hr,
      commands.title,
      commands.divider,
      commands.link,
      commands.quote,
      commands.code,
      commands.codeBlock
    ]

    if (!!onImageUpload) {
      commandsList.push(commands.divider)
      commandsList.push({
        name: "image",
        keyCommand: "image",
        buttonProps: {
          "aria-label": "Insert image"
        },
        icon: (
          <Box
            width="13px"
            height="13px"
            display="inline-block"
            position="relative"
            sx={{cursor: "pointer"}}>
            <input
              ref={fileInputRef}
              type="file"
              onChange={handleFileSelect}
              style={{
                opacity: 0,
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0
              }}
            />
            <svg width="13" height="13" viewBox="0 0 20 20">
              <path
                fill="currentColor"
                d="M15 9c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm4-7H1c-.55 0-1 .45-1 1v14c0 .55.45 1 1 1h18c.55 0 1-.45 1-1V3c0-.55-.45-1-1-1zm-1 13l-6-5-2 2-4-5-4 8V4h16v11z"
              />
            </svg>
          </Box>
        ),
        execute: (_state, api) => {
          setEditorApi(api)
        }
      })
    }

    return {
      "data-color-mode": "light",
      height: "100%",
      visibleDragbar: false,
      commands: commandsList
    }
  }, [editorApi])

  function handleFileSelect(e:  React.ChangeEvent<HTMLInputElement>) {
    const file = e.target.files?.[0] || null

    if (file && onImageUpload) {
      onImageUpload(file).then(url => {
        if (url && editorApi) {
          const modifyText = `\n![](${url})\n`

          editorApi.replaceSelection(modifyText)
        } else {
          onError && onError("Something went wrong")
        }
      }).catch(err => {
        onError && onError(err)
      }).finally(() => {
        if (fileInputRef.current) {
          fileInputRef.current.value = ""
        }
      })
    }
  }

  const handleChange = (newValue: string | undefined) => {
    editable && onChange && onChange(newValue || "")
  }

  return (
    <Box
      data-color-mode="light"
      width="100%"
      sx={{
        fontFamily
      }}>
      {editable ? (
        <MDEditor
          value={value}
          onChange={handleChange}
          style={{
            backgroundColor: "transparent"
          }}
          {...config}
        />
      ) : (
        <MDEditor.Markdown
          disableCopy={inactive}
          style={{
            display: !fullWidth && "inline-block",
            backgroundColor: "transparent",
            fontFamily,
            fontWeight: "500"
          }}
          source={value}
        />
      )}
    </Box>
  )
}
