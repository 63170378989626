import React, {useMemo} from "react"
import formatDate from "utils/formatDate"
import {
  Box,
  Button,
  Grid,
  Typography
} from "@mui/material"
import ExamSimulatorIcon from "assets/icons/ExamSimulator"
import PracticeModeIcon from "assets/icons/PracticeMode"
import WPLabIcon from "assets/icons/WPLab"
import {useSelector} from "store"

export type ExamType = "simulator" | "practice" |"lab"

interface Props {
  type: ExamType
  expirationDate: string
  onClick: (type: ExamType) => void
  expired: boolean
}

export default function PracticeModeCard({
  type,
  expirationDate,
  onClick,
  expired
}: Props) {
  const user = useSelector((store) => store.userSlice)

  const cardData = useMemo(() => {
    return {
      simulator: {
        title: "Exam simulator",
        icon: <ExamSimulatorIcon/>,
        buttonTitle: "START SIMULATION"
      },
      practice: {
        title: "Practice test",
        icon: <PracticeModeIcon/>,
        buttonTitle: "START TEST"
      },
      lab: {
        title: "WordPress Lab",
        icon: <WPLabIcon/>,
        buttonTitle: user.totalTimeSpentInLabs ? "RESUME ACCESS" : "START LAB"
      }
    }
  }, [user])

  return (
    <Grid item xs={12} sm={6} md={4}>
      <Box
        sx={{
          height: "100%",
          bgcolor: (theme) => theme.palette.white.main,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          borderRadius: "20px",
          pt: 5,
          overflow: "hidden"
        }}>
        <Box px={3} textAlign="center">
          <Typography variant="h5">
            {cardData[type].title}
          </Typography>
        </Box>
        <Box>
          <Box
            px={3}
            mb={3}
            mt={3}>
            <Box
              sx={{
                width: "60%",
                mx: "auto"
              }}>
              {cardData[type].icon}
            </Box>
            <Box
              sx={{
                textAlign: "center",
                mt: 5
              }}>
              <Button
                size="large"
                variant="contained"
                // fullWidth
                // color={data.expired ? "secondary" : "primary"}
                disabled={expired}
                onClick={() => onClick(type)}>
                {cardData[type].buttonTitle}
              </Button>
            </Box>
          </Box>
          <Box
            sx={{
              opacity: expirationDate ? 1 : 0,
              display: "flex",
              justifyContent: "center",
              width: "100%",
              bgcolor: (theme) => expired ? theme.palette.error.main : theme.palette.success.main,
              py: 1.25
            }}>
            <Typography variant="body1" color="white.main">
              {expired ? "Expired" : "Valid until"} {formatDate(expirationDate)}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Grid>
  )
}
