import * as Sentry from "@sentry/react"
import ReactGA from "react-ga4"
import client from "api/apollo"
import {createContext, useEffect, useReducer, useState} from "react"
import type {FC} from "react"
import PropTypes, {InferProps} from "prop-types"
import * as Auth from "aws-amplify/auth"
import {userService} from "services/user.service"
import {IUserInfo} from "types/common"
import {getUserRoles} from "utils/security"

const authProviderProps = {
  children: PropTypes.element.isRequired
}

interface State {
  isInitialized: boolean
  isAuthenticated: boolean
  user: IUserInfo | null
}

interface RegisterProps {
  firstName: string
  lastName: string
  email: string
  password: string
  // birthdate: string
  // phone: string
  // address: string
  city: string
  country: string
  state: string
  postcode: string
}

type UpdateProps = Omit<RegisterProps, "password">

interface AuthContextValue extends State {
  loading: boolean
  platform: "Amplify"
  initialize: () => Promise<void>
  login: (email: string, password: string) => Promise<void>
  logout: () => Promise<void>
  register: (data: RegisterProps) => Promise<void>
  update: (data: UpdateProps) => Promise<void>
  verifyCode: (username: string, code: string) => Promise<void>
  resendCode: (username: string) => Promise<void>
  passwordRecovery: (username: string) => Promise<void>
  passwordReset: (
    username: string,
    code: string,
    newPassword: string
  ) => Promise<void>
}

type InitializeAction = {
  type: "INITIALIZE"
  payload: {
    isAuthenticated: boolean
    user: State["user"]
  }
}

type LoginAction = {
  type: "LOGIN"
  payload: {
    user: State["user"]
  }
}

type LogoutAction = {
  type: "LOGOUT"
}

type RegisterAction = {
  type: "REGISTER"
}

type VerifyCodeAction = {
  type: "VERIFY_CODE"
}

type UpdateAction = {
  type: "UPDATE"
}

type ResendCodeAction = {
  type: "RESEND_CODE"
}
type PasswordRecoveryAction = {
  type: "PASSWORD_RECOVERY"
}

type PasswordResetAction = {
  type: "PASSWORD_RESET"
}

type Action =
  | InitializeAction
  | LoginAction
  | LogoutAction
  | RegisterAction
  | VerifyCodeAction
  | UpdateAction
  | ResendCodeAction
  | PasswordRecoveryAction
  | PasswordResetAction

const initialState: State = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
}

const handlers: Record<string, (state: State, action: Action) => State> = {
  INITIALIZE: (state: State, action: InitializeAction): State => {
    const {isAuthenticated, user} = action.payload

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    }
  },
  LOGIN: (state: State, action: LoginAction): State => {
    const {user} = action.payload

    return {
      ...state,
      isAuthenticated: true,
      user
    }
  },
  LOGOUT: (state: State): State => ({
    ...state,
    isAuthenticated: false,
    user: null
  }),
  REGISTER: (state: State): State => ({...state}),
  VERIFY_CODE: (state: State): State => ({...state}),
  RESEND_CODE: (state: State): State => ({...state}),
  PASSWORD_RECOVERY: (state: State): State => ({...state}),
  PASSWORD_RESET: (state: State): State => ({...state})
}

const reducer = (state: State, action: Action): State =>
  handlers[action.type] ? handlers[action.type](state, action) : state

const AuthContext = createContext<AuthContextValue>({
  ...initialState,
  platform: "Amplify",
  loading: false,
  initialize: () => Promise.resolve(),
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  update: () => Promise.resolve(),
  verifyCode: () => Promise.resolve(),
  resendCode: () => Promise.resolve(),
  passwordRecovery: () => Promise.resolve(),
  passwordReset: () => Promise.resolve()
})

export const AuthProvider: FC<InferProps<typeof authProviderProps>> = ({
  children
}) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const [loading, setIsLoading] = useState(false)

  const initialize = async (): Promise<void> => {
    try {
      const userInfo = await userService.fetchUserInfo()

      Sentry.setUser({email: userInfo.email})
      ReactGA.set({userId: userInfo.cognitoUserId})

      dispatch({
        type: "INITIALIZE",
        payload: {
          isAuthenticated: true,
          user: {
            ...userInfo,
            roles: getUserRoles(userInfo)
          }
        }
      })
    } catch (error) {
      dispatch({
        type: "INITIALIZE",
        payload: {
          isAuthenticated: false,
          user: null
        }
      })
    }
  }

  useEffect(() => {
    initialize()
  }, [])

  const login = async (email: string, password: string): Promise<void> => {
    try {
      setIsLoading(true)

      await Auth.signIn({
        username: email,
        password,
        options: {
          authFlowType: "USER_PASSWORD_AUTH"
        }
      })

      const userInfo = await userService.fetchUserInfo()

      dispatch({
        type: "LOGIN",
        payload: {
          user: {
            ...userInfo,
            roles: getUserRoles(userInfo)
          }
        }
      })
    } catch (err) {
      setIsLoading(false)
      console.warn(err)
      throw err
    }
  }

  const logout = async (): Promise<void> => {
    setIsLoading(true)

    await Auth.signOut()
    setTimeout(async () => {
      await client.clearStore()
    }, 0)

    dispatch({type: "LOGOUT"})

    setIsLoading(false)
  }

  const register = async ({
    firstName,
    lastName,
    email,
    password,
    // birthdate,
    // phone,
    // address,
    city,
    country,
    state,
    postcode
  }: RegisterProps): Promise<void> => {
    setIsLoading(true)

    await Auth.signUp({
      username: email,
      password,
      options: {
        userAttributes:{
          given_name: firstName,
          family_name: lastName,
          // phone_number: phone,
          email,
          // birthdate,
          address: JSON.stringify({
            // address,
            city,
            country,
            state,
            postcode
          })
        }
      }
    })

    setIsLoading(false)

    dispatch({type: "REGISTER"})
  }

  const update = async ({
    firstName,
    lastName,
    email,
    // birthdate,
    // phone,
    // address,
    city,
    country,
    state,
    postcode
  }: UpdateProps): Promise<void> => {
    // const user = await Auth.currentAuthenticatedUser()
    await Auth.updateUserAttributes({
      userAttributes: {
        given_name: firstName,
        family_name: lastName,
        // phone_number: phone,
        email,
        // birthdate,
        address: JSON.stringify({
          // address,
          city,
          state,
          country,
          postcode
        })
      }
    })

    await initialize()

    dispatch({type: "UPDATE"})
  }

  /**
   * @deprecated This method is deprecated as of January 2024.
   * Email verification step has been removed from the authentication flow.
   */
  const verifyCode = async (username: string, code: string): Promise<void> => {
    console.warn(
      "verifyCode is deprecated. Email verification step has been removed from the authentication flow."
    )

    await Auth.confirmSignUp({username, confirmationCode: code})
    dispatch({type: "VERIFY_CODE"})
  }

  /**
   * @deprecated This method is deprecated as of January 2024.
   * Email verification step has been removed from the authentication flow.
   */
  const resendCode = async (username: string): Promise<void> => {
    console.warn(
      "resendCode is deprecated. Email verification step has been removed from the authentication flow."
    )

    await Auth.resendSignUpCode({username})
    dispatch({type: "RESEND_CODE"})
  }

  const passwordRecovery = async (username: string): Promise<void> => {
    await Auth.resetPassword({username})

    dispatch({type: "PASSWORD_RECOVERY"})
  }

  const passwordReset = async (
    username: string,
    code: string,
    newPassword: string
  ): Promise<void> => {
    await Auth.confirmResetPassword({username, confirmationCode: code, newPassword})

    dispatch({type: "PASSWORD_RESET"})
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        platform: "Amplify",
        initialize,
        login,
        logout,
        register,
        update,
        verifyCode,
        resendCode,
        passwordRecovery,
        passwordReset,
        loading
      }}>
      {children}
    </AuthContext.Provider>
  )
}

AuthProvider.propTypes = authProviderProps

export default AuthContext
