import {Box, Checkbox, FormControlLabel, FormGroup, Radio, RadioGroup, Typography} from "@mui/material"
import MD from "components/MD"
import {isEqual} from "lodash"
import {PropsWithChildren, useEffect, useMemo, useState} from "react"

interface CommonProps {
  description?: string
  code?: string
  codeLang?: string
  options: Array<{
    id: string
    content: string
  }>
  config?: {
    fontFamily?: string
    codeBgColor?: string
    optionBgColor?: string
    optionInnerColor?: string
    optionBgColorActive?: string
    optionInnerColorActive?: string
  }
  answer?: string[] | null
  givenAnswer?: string[]
  inactive?: boolean
}

interface SingleTypeProps {
  multiple?: false
  onChange?: (value: string) => void
}

interface MultipleTypeProps {
  multiple: true
  onChange?: (value: string[]) => void
}

type Props = CommonProps & (SingleTypeProps | MultipleTypeProps)

export default function SimpleTask({
  multiple,
  description,
  code,
  codeLang,
  options,
  onChange,
  config: configProp,
  answer,
  givenAnswer,
  inactive
}: Props) {
  const [localOptions, setLocalOptions] = useState<Props["options" | null]>(null)
  const [value, setValue] = useState<string | string[]>((multiple ? [] : ""))

  const config: Props["config"] = useMemo(() => ({
    fontFamily: configProp?.fontFamily || "Roboto, sans-serif",
    codeBgColor: configProp?.codeBgColor || "rgba(32, 146, 192, 0.04)",
    optionBgColor: configProp?.optionBgColor || "rgba(0, 40, 85, 0.04)",
    optionInnerColor: configProp?.optionInnerColor || "rgba(0, 40, 85, 1)",
    optionBgColorActive: configProp?.optionBgColorActive || "rgba(0, 40, 85, 1)",
    optionInnerColorActive: configProp?.optionInnerColorActive || "#ffffff"
  }), [])

  const MDStyles = useMemo(() => {
    return {
      "& .wmde-markdown code": {
        backgroundColor: "transparent"
      },
      "& .wmde-markdown pre > code": {
        px: 2,
        py: 1.5,
        backgroundColor: config.codeBgColor
      },
      "& .wmde-markdown pre .copied": {
        top: 10,
        right: 10
      }
    }
  }, [config])

  const correctAnswer = useMemo(() => {
    if (options && answer) {
      return options.filter(i => answer.includes(i.id))
    } else {
      return null
    }
  }, [options, answer])

  useEffect(() => {
    if (!options?.length) {
      setLocalOptions([])
    } else {
      setLocalOptions(options)
    }
  }, [options])

  useEffect(() => {
    if (givenAnswer?.length) {
      setValue(current => {
        if (isEqual(givenAnswer, value)) {
          return current
        }

        return multiple ? givenAnswer : givenAnswer[0]
      })
    }
  }, [givenAnswer, multiple])

  useEffect(() => {
    if (onChange) {
      if (multiple === true) {
        onChange(value as string[])
      } else {
        onChange(value as string)
      }
    }
  }, [multiple, value])

  const handleChange = (v: string) => {
    if (inactive) return

    if (multiple) {
      setValue((current: string[]) => {
        const exists = current.includes(v)

        return exists ? (
          current.filter(i => i !== v)
        ) : (
          options.map(i => i.id).filter(i => {
            return current.includes(i) || i === v
          })
        )
      })
    } else {
      setValue(v)
    }
  }

  const GroupWrapper = useMemo(() => {
    return ({children}: PropsWithChildren<{}>) => {
      if (!multiple) {
        return (
          <RadioGroup
            sx={{
              flexDirection: "column",
              gap: 1
            }}
            value={value}
            onChange={(_, v) => handleChange(v)}>
            {children}
          </RadioGroup>
        )
      } else {
        return (
          <FormGroup
            sx={{
              flexDirection: "column",
              gap: 1
            }}>
            {children}
          </FormGroup>
        )
      }
    }
  }, [multiple, value])

  return (
    <Box position="relative" display="inline-block" minWidth="500px" maxWidth="800px">
      <Box mb={4}>
        <Box display="flex" gap={1} sx={MDStyles}>
          <Typography fontWeight="700">
            Question:
          </Typography>
          <MD
            fontFamily={config.fontFamily}
            value={description}
          />
        </Box>
        {code && (
          <Box
            sx={{
              position: "relative",
              width: "100%",
              display: "flex",
              mt: 1.5,
              borderRadius: "4px",
              ...MDStyles
            }}>
            <MD
              fontFamily={config.fontFamily}
              value={"```" + ((codeLang + "\n") || "") + code}
              fullWidth
            />
          </Box>
        )}
      </Box>
      <Box position="relative" width="100%">
        {localOptions && (
          <GroupWrapper>
            {localOptions.map(i => {
              const isActive = multiple ? value.includes(i.id) : i.id === value

              return (
                <FormControlLabel
                  key={i.id}
                  sx={{
                    position: "relative",
                    width: "100%",
                    display: "flex",
                    px: 1,
                    py: .5,
                    ml: 0,
                    mr: 0,
                    backgroundColor: isActive ? config.optionBgColorActive : config.optionBgColor,
                    borderRadius: "4px",
                    fontFamily: config.fontFamily,
                    transition: "0.3s",
                    "& > .MuiFormControlLabel-label": {
                      color: isActive ? config.optionInnerColorActive : config.optionInnerColor,
                      transition: "0.3s"
                    },
                    pointerEvents: inactive ? "none" : "auto"
                  }}
                  value={i.id}
                  control={
                    multiple ? (
                      <Checkbox
                        tabIndex={-1}
                        checked={isActive}
                        onChange={() => handleChange(i.id)}
                        sx={{
                          color: isActive ? config.optionInnerColorActive : config.optionInnerColor,
                          transition: "0.3s",
                          "&.Mui-checked": {
                            color: isActive ? config.optionInnerColorActive : config.optionInnerColor,
                            transition: "0.3s"
                          }
                        }}
                      />
                    ) : (
                      <Radio
                        tabIndex={-1}
                        sx={{
                          color: isActive ? config.optionInnerColorActive : config.optionInnerColor,
                          transition: "0.3s",
                          "&.Mui-checked": {
                            color: isActive ? config.optionInnerColorActive : config.optionInnerColor,
                            transition: "0.3s"
                          }
                        }}
                      />
                    )
                  }
                  label={i.content}
                />
              )
            })}
          </GroupWrapper>
        )}
        {correctAnswer && (
          <Box
            position="relative"
            width="100%"
            mt={4}
            py={2}
            px={2.5}
            borderRadius="6px"
            bgcolor="rgba(39, 174, 96, 0.1)"
            overflow="hidden">
            <Box
              position="absolute"
              width={4}
              height="100%"
              top={0}
              left={0}
              bgcolor="rgba(39, 174, 96, 1)"
            />
            <Typography mb={1} fontFamily={config.fontFamily}>
              <strong>
                Right answer:
              </strong>
            </Typography>
            <Box display="flex" flexDirection="column" gap={2}>
              {correctAnswer.map(i => (
                <Box
                  key={i.id}
                  py={!multiple ? 1 : 1.5}
                  px={1}
                  borderRadius="6px"
                  bgcolor="rgba(39, 174, 96, 0.1)"
                  display="flex"
                  alignItems="center"
                  gap={1}>
                  {!multiple && (
                    <Radio
                      tabIndex={-1}
                      checked
                      sx={{
                        p: 0,
                        color: "rgba(39, 174, 96, 1)",
                        pointerEvents: "none",
                        "&.Mui-checked": {
                          color: "rgba(39, 174, 96, 1)"
                        }
                      }}
                    />
                  )}
                  <Typography fontFamily={config.fontFamily}>
                    {i.content}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  )
}
