import {useLazyQuery, useMutation} from "@apollo/client"
import {Box, Button, FormControlLabel, Grid, Radio, RadioGroup, Stack, TextField, Typography} from "@mui/material"
import CREATE_SUB_LICENSE from "api/apollo/mutations/CREATE_SUB_LICENSE"
import GET_DISTRICT_IDS from "api/apollo/queries/GET_DISTRICT_IDS"
import GET_SCHOOL_IDS from "api/apollo/queries/GET_SCHOOL_IDS"
import GET_SCHOOL_STAFF_IDS from "api/apollo/queries/GET_SCHOOL_STAFF_IDS"
import DataTable from "components/DataTable"
import {DataTableQueryUpdate, DataTableSchema} from "components/DataTable/types.t"
import RangeSlider from "components/RangeSlider"
import SelectAsync from "components/SelectAsync"
import {useFormik} from "formik"
import {
  CreateSubLicenseMutation,
  CreateSubLicenseMutationVariables,
  GetDistrictIdsQuery,
  GetDistrictIdsQueryVariables,
  GetSchoolIdsQuery,
  GetSchoolIdsQueryVariables,
  GetSchoolStaffIdsQuery,
  GetSchoolStaffIdsQueryVariables,
  License,
  School,
  SortOrder,
  User
} from "generated/graphql"
import React, {useEffect, useMemo, useState} from "react"
import {useDispatch} from "store"
import {handleError, notifyUser} from "store/slices/notifier/notifier"
import {QueryDataType} from "types/typeUtils"
import * as Yup from "yup"

interface Props {
  license: Partial<License>
  districtId?: string
  onAfterSubmit: () => void
}

export default function TransferLicenseForm(props: Props) {
  const dispatch = useDispatch()

  const [staffData, setStaffData] = useState<QueryDataType<Partial<User>>>(null)
  const [selectedTeachers, setSelectedTeachers] = useState<string[]>([])
  const [licenseAssignment, setLicenseAssignment] = useState<
    "ALL_TEACHERS" | "SPECIFIC_TEACHERS"
  >("ALL_TEACHERS")

  const [selectedSchool, setSelectedSchool] = useState<Partial<School> | null>(null)

  const [districtId, setDistrictId] = useState("")

  const [schoolsQueryFetch, schoolsQuery] = useLazyQuery<
    GetSchoolIdsQuery,
    GetSchoolIdsQueryVariables
  >(GET_SCHOOL_IDS)

  const [districtQueryFetch, districtQuery] = useLazyQuery<
    GetDistrictIdsQuery,
    GetDistrictIdsQueryVariables
  >(GET_DISTRICT_IDS)

  const [staffQueryFetch, staffQuery] = useLazyQuery<
    GetSchoolStaffIdsQuery,
    GetSchoolStaffIdsQueryVariables
  >(GET_SCHOOL_STAFF_IDS)

  const [createSubLicense, {loading}] = useMutation<
    CreateSubLicenseMutation,
    CreateSubLicenseMutationVariables
  >(CREATE_SUB_LICENSE)

  const teachersError = useMemo(() => {
    return !staffData && (staffQuery.error || null)
  }, [staffData, staffQuery.error])

  const districts = useMemo(() => {
    return districtQuery.data?.districts.items || []
  }, [districtQuery.data])

  const schools = useMemo(() => {
    return schoolsQuery.data?.schools.items || []
  }, [schoolsQuery.data])

  const formik = useFormik({
    initialValues: {
      schoolId: "",
      courses: 0,
      exams: 0,
      practices: 0,
      labs: 0,
      newLicenseName: props.license?.name || ""
    },
    validationSchema: Yup.object().shape({
      schoolId: Yup.string().required("License is required"),
      courses: Yup.number().integer().max(props.license?.coursesRemain),
      exams: Yup.number().integer().max(props.license?.examVouchersRemain),
      practices: Yup.number().integer().max(props.license?.practiceVouchersRemain),
      labs: Yup.number().integer().max(props.license?.labsRemain),
      newLicenseName: Yup.string().required("Name is required")
    }),
    onSubmit: values => {
      if (licenseAssignment === "SPECIFIC_TEACHERS" && !selectedTeachers.length) {
        dispatch(notifyUser({
          message: "Choose teachers first, please",
          variant: "info"
        }))
        return
      }
      if (!!(values.courses || values.exams || values.practices || values.labs)) {
        createSubLicense({
          variables: {
            fromLicenseId: props.license._id,
            newLicenseName: values.newLicenseName,
            quota: {
              courses: values.courses,
              exams: values.exams,
              practices: values.practices,
              labs: values.labs
            },
            scope: {
              schoolId: values.schoolId,
              teachersScope: selectedTeachers
            }
          }
        }).then(() => {
          props.onAfterSubmit()
        }).catch(err => {
          dispatch(handleError(err))
        })
      }
    }
  })

  const handleQuerySchools = (value: string) => {
    const selectedDistrictId = props.districtId || districtId || undefined

    schoolsQueryFetch({
      variables: {
        search: value,
        districtIds: (selectedDistrictId === "No district" || !selectedDistrictId) ? undefined : [selectedDistrictId],
        sortBy: "name",
        order: SortOrder.Asc
      }
    })
  }

  const handleQueryDistricts = (value: string) => {
    districtQueryFetch({
      variables: {
        search: value === "No district" ? "" : value
      }
    })
  }

  const tableSchema: DataTableSchema<User> = useMemo(() => {
    return [
      {
        type: "text",
        headerText: "First Name",
        fieldName: "firstName",
        sort: "firstName",
        headerNoWrap: true,
        contentWrap: "nowrap"
      },
      {
        type: "text",
        headerText: "Last Name",
        fieldName: "lastName",
        sort: "lastName",
        headerNoWrap: true,
        contentWrap: "nowrap"
      },
      {
        type: "checkbox",
        key: data => data._id,
        checked: data => {
          return selectedTeachers.includes(data._id)
        },
        handler: (selected, data) => {
          if (selected) {
            setSelectedTeachers(prevState => [...prevState, data._id])
          } else {
            setSelectedTeachers(prevState => prevState.filter(i => i !== data._id))
          }
        }
      }
    ]
  }, [staffData, selectedTeachers])

  const handleQuery: DataTableQueryUpdate<{
    refetch?: boolean
  }> = (state, options) => {
    const fetchPolicy = options?.refetch ? "network-only" : undefined
    const {itemsPerPage, page, sort, searchQuery} = state

    if (selectedSchool) {
      staffQueryFetch({
        fetchPolicy,
        variables: {
          schoolId: selectedSchool._id,
          take: itemsPerPage,
          offset: itemsPerPage * page,
          sortBy: sort?.key,
          order: sort?.order,
          search: searchQuery
        }
      }).then(res => {
        setStaffData(res.data?.getSchool?.staff || null)
      })
    }
  }

  useEffect(() => {
    const id = formik.values.schoolId
    setSelectedSchool(id ? schools.find(i => i._id === id) : null)
    setSelectedTeachers([])
    setStaffData(null)
    setLicenseAssignment("ALL_TEACHERS")
  }, [formik.values.schoolId])

  useEffect(() => {
    setSelectedTeachers([])
  }, [licenseAssignment])

  return (
    <Box>
      <form onSubmit={formik.handleSubmit}>
        <Box px={4} minWidth="54vw">
          <Typography variant="h5" textAlign="center" p={2} mb={2}>
            Transfer inventory
          </Typography>
          <Box mb={2}>
            <Typography variant="h6" mb={2}>
              Transfer from
            </Typography>
            {props.license &&
              <Stack spacing={1}>
                {props.license?.district &&
                  <Typography variant="subtitle1">
                    District name: {props.license.district.name}
                  </Typography>
                }
                <Typography variant="subtitle1">
                  Account name: {props.license.school.name}
                </Typography>
                <Typography variant="subtitle1">
                  License ID: {props.license._id.match(/.{1,4}/g).join("-")}
                </Typography>
                <Typography variant="subtitle1">
                  License name: {props.license.name}
                </Typography>
              </Stack>
            }
          </Box>
          <Box mb={2}>
            <Typography variant="h6" mb={2}>
              Transfer to
            </Typography>
            <Grid container spacing={3} direction="row" maxWidth="860px">
              {!props?.districtId && (
                <Grid item xs={6}>
                  <SelectAsync
                    id="districtId"
                    label="District"
                    value={districtId}
                    onChange={value => {
                      setDistrictId(value)
                      setSelectedSchool(null)
                      formik.setFieldValue("schoolId", "")
                    }}
                    loading={districtQuery.loading}
                    options={[
                      {label: "No district", value: "No district"},
                     ...districts.map(i => ({
                       label: i.name,
                       value: i._id
                     }))
                    ]}
                    onUpdateOptions={handleQueryDistricts}
                  />
                </Grid>
              )}
              <Grid item xs={6}>
                <SelectAsync
                  id="schoolId"
                  label="School"
                  error={formik.touched["schoolId"] && formik.errors["schoolId"]}
                  touched={formik.touched["schoolId"]}
                  value={formik.values["schoolId"]}
                  onChange={(value) => formik.setFieldValue("schoolId", value)}
                  loading={schoolsQuery.loading}
                  options={schools.map(i => ({
                    label: i.name,
                    value: i._id
                  }))}
                  onUpdateOptions={handleQuerySchools}
                />
              </Grid>
            </Grid>
          </Box>
          {selectedSchool && (
            <Box mb={2}>
              <Typography variant="h6" mb={2}>
                Assign inventory to specific teacher
              </Typography>
              <Grid container spacing={3} direction="row" maxWidth="860px">
                <Grid item xs={6}>
                  <RadioGroup
                    value={licenseAssignment}
                    onChange={((_e, value: typeof licenseAssignment) => setLicenseAssignment(value))}>
                    <FormControlLabel value="ALL_TEACHERS" control={<Radio />} label="No, allow access to all teachers" />
                    <FormControlLabel value="SPECIFIC_TEACHERS" control={<Radio />} label="Yes, select teachers" />
                  </RadioGroup>
                </Grid>
              </Grid>
            </Box>
          )}
          {selectedSchool && licenseAssignment === "SPECIFIC_TEACHERS" &&
            <Box minHeight="20vh">
              <Typography variant="h6" mb={2}>
                Teachers
              </Typography>
              <DataTable
                schema={tableSchema}
                data={staffData?.items}
                error={!!teachersError}
                itemsTotalCount={staffData?.total}
                emptyDataMessage={selectedSchool ? "Teachers not found" : "Please select a school"}
                onQueryUpdate={handleQuery}
                search="Search Teachers"
                loading={staffQuery.loading}
                lastPage={!staffData?.hasMore}
              />
            </Box>
          }
          <Box mb={2}>
            <Typography variant="h6" mb={2}>
              Transfer Details
            </Typography>
            <Grid container spacing={3} direction="row" maxWidth="860px">
              <Grid item xs={6}>
                <TextField
                  name="newLicenseName"
                  value={formik.values["newLicenseName"]}
                  error={!!(formik.touched["newLicenseName"] && formik.errors["newLicenseName"])}
                  helperText={formik.touched["newLicenseName"] && formik.errors["newLicenseName"]}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  label="New License Name"
                  fullWidth
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <RangeSlider
                  onChange={value => formik.setFieldValue("courses", value)}
                  label="Courses"
                  maxValue={props.license?.coursesRemain}
                />
              </Grid>
              <Grid item xs={12}>
                <RangeSlider
                  onChange={value => formik.setFieldValue("exams", value)}
                  label="Exams"
                  maxValue={props.license?.examVouchersRemain}
                />
              </Grid>
              <Grid item xs={12}>
                <RangeSlider
                  onChange={value => formik.setFieldValue("practices", value)}
                  label="Practices"
                  maxValue={props.license?.practiceVouchersRemain}
                />
              </Grid>
              <Grid item xs={12}>
                <RangeSlider
                  onChange={value => formik.setFieldValue("labs", value)}
                  label="Labs"
                  maxValue={props.license?.labsRemain}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box display="flex" justifyContent="end" p={4}>
          <Button
            type="submit"
            variant="contained"
            disabled={!(formik.values.courses || formik.values.exams || formik.values.practices || formik.values.labs) || loading}>
            Transfer
          </Button>
        </Box>
      </form>
    </Box>
  )
}
