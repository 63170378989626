import SessionResultProgress from "components/SessionResultProgress"
import {
  Box,
  Typography,
  Button,
  Paper,
  Grid,
  Link
} from "@mui/material"
import {LINKS} from "consts/links"
import {
  ExamSession,
  ExamSessionStatus,
  TestingSession,
  TestingSessionStatus
} from "generated/graphql"
import {useMemo} from "react"
import {Link as RouterLink} from "react-router-dom"
import formatDate from "utils/formatDate"
import RestartAltIcon from "@mui/icons-material/RestartAlt"
import getSessionDuration from "utils/getSessionDuration"
import getExamSessionStatusName from "utils/getExamSessionStatusName"
import {kpSecondaryColors} from "consts"

interface Props {
  isPractice: boolean
  data: DeepPartial<ExamSession | TestingSession>
}

export default function Info({
  isPractice,
  data
}: Props) {
  const status = useMemo(() => {
    let color = null

    if ([TestingSessionStatus.Finished, ExamSessionStatus.Finished].includes(data.status)) {
      color = "success.main"
    }

    if ([ExamSessionStatus.Canceled].includes(data.status as ExamSessionStatus)) {
      color = "error.main"
    }

    return {
      name: getExamSessionStatusName(data.status),
      color
    }
  }, [data.status])

  return (
    <Box display="flex" flexDirection="column" gap={2.5}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          bgcolor: "white.main",
          borderRadius: "20px",
          pr: 3,
          pl: 2,
          py: 3,
          gap: 1
        }}>
        <Box display="flex" alignItems="center" gap={2}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
              maxWidth: 160,
              width: "100%"
            }}>
            <img
              src={`/examIcons/${data.exam.code}.png`}
              alt={data.exam.displayCode}
              style={{
                width: "100%",
                aspectRatio: 1,
                objectFit: "contain"
              }}
            />
          </Box>
          <Box display="flex" flexDirection="column">
            <Typography variant="h5" fontWeight={900}>
              {data.exam.displayName?.value} ({data.exam.displayCode}) {isPractice && " - Practice Test"}
            </Typography>
            <Typography sx={{mt: 1, mb: 2}}>
              Candidate: {data.user.fullName}
            </Typography>
            <Box>
              <Link to={LINKS.dashboard} component={RouterLink} underline="none">
                <Button
                  startIcon={<RestartAltIcon/>}
                  variant="square"
                  color="lightBlue"
                  size="small">
                  Retake
                </Button>
              </Link>
            </Box>
          </Box>
        </Box>
        <Paper
          sx={{
            p: 1.5,
            borderRadius: 2.5
          }}>
          <SessionResultProgress
            size={180}
            passingScore={data.exam.passingScoreRate}
            value={data._score}
          />
        </Paper>
      </Box>
      <Grid
        container
        p={3}
        sx={{
          bgcolor: "white.main",
          borderRadius: "20px",
          textAlign: "center",
          "& > div": {
            borderRight: `1px solid ${kpSecondaryColors.lightGrey}`
          },
          "& > div:last-of-type": {
            borderRight: "none"
          }
        }}>
        <Grid item xs={2}>
          <Typography variant="body1" fontWeight={900} mb={1}>
            Exam Code
          </Typography>
          <Typography variant="body1">
            {data.exam.displayCode}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="body1" fontWeight={900} mb={1}>
            Score
          </Typography>
          <Typography variant="body1">
            {data._score}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="body1" fontWeight={900} mb={1}>
            Status
          </Typography>
          <Typography variant="body1">
            {getExamSessionStatusName(data.status)}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="body1" fontWeight={900} mb={1}>
            Date
          </Typography>
          <Typography variant="body1">
            {formatDate(data.startDate || data.createdAt)}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="body1" fontWeight={900} mb={1}>
            Time Used
          </Typography>
          <Typography variant="body1">
            {getSessionDuration(data.startDate || data.createdAt, data.finishDate)}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="body1" fontWeight={900} mb={1}>
            Show Answers
          </Typography>
          <Typography variant="body1">
            {"options" in data && data.options?.showResult !== "end" ? "Task level" : "End of test"}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  )
}
