import {useMemo} from "react"
import Chart from "react-apexcharts"
import {
  Box,
  Typography,
  Link,
  Divider,
  Paper
} from "@mui/material"
import {useTheme} from "@mui/material"
import {ResultObjective} from "generated/graphql"
import FileDownloadIcon from "@mui/icons-material/FileDownloadOutlined"
import {kpSecondaryColors} from "consts"
import hexToRgba from "utils/hexToRgba"
import {Link as RouterLink} from "react-router-dom"
import ActionsMenu, {ActionItem} from "components/ActionsMenu"
import ApexCharts from "apexcharts"

interface Props {
  objectives: ResultObjective[]
  isPassed: boolean | null
  isPractice: boolean
  examCode: string
  examName: string
}

const itemHeight = 40

export default function ObjectiveResults({
  objectives,
  isPassed,
  isPractice,
  examCode,
  examName
}: Props) {
  const theme = useTheme()
  const chart: Chart["props"] = useMemo(() => {
    if (objectives.length) {
      const sortedObjectives = [
        ...(objectives ?? [])
      ].sort((a, b) => {
        return parseInt(a.title) - parseInt(b.title)
      })

      return {
        options: {
          chart: {
            id: "objective-results-chart",
            background: "transparent",
            stacked: false,
            toolbar: {
              show: false
            }
          },
          annotations: {
            yaxis: sortedObjectives.map((_i, index) => ({
              y: "0px",
              strokeDashArray: 0,
              width: "24px",
              borderColor: theme.palette.divider,
              offsetX: -((index * itemHeight) + 32)
            }))
          },
          fill: {
            opacity: .9
          },
          colors: sortedObjectives.map(({score}) => score > 74 ? kpSecondaryColors.brightGreen : theme.palette.error.main),
          dataLabels: {
            enabled: true,
            formatter: (v) => v === 0 ? "" : `${v}%`,
            style: {
              fontSize: "14px",
              fontWeight: "700",
              colors: [(opts) => (
                opts.series[0][opts.dataPointIndex] < 4 ? theme.palette.primary.main : theme.palette.white.main
              )]
            }
          },
          grid: {
            position: "back",
            borderColor: theme.palette.divider,
            strokeDashArray: 4,
            yaxis: {
              lines: {
                show: true
              },
              offsetY: "20%"
            },
            xaxis: {
              lines: {
                show: false
              }
            }
          },
          legend: {
            show: false
          },
          plotOptions: {
            bar: {
              horizontal: true,
              borderRadius: 4,
              borderRadiusApplication: "end",
              barHeight: "24px",
              dataLabels: {
                position: "center"
              }
            }
          },
          tooltip: {
            y: {formatter: (v: number): string => `${v}%`},
            style: {
              fontSize: "14px",
              fontFamily: theme.typography.fontFamily
            }
          },
          yaxis: {
            labels: {
              show: true,
              minWidth: 150,
              maxWidth: 300,
              style: {
                fontSize: "14px",
                fontFamily: theme.typography.fontFamily,
                colors: hexToRgba(theme.palette.primary.main, .7),
                fontWeight: 500
              },
              offsetX: 0,
              offsetY: 3,
              rotate: 0,
              formatter: (value) => {
                return String(value)
              }
            }
          },
          xaxis: {
            max: 100,
            axisBorder: {
              color: theme.palette.divider,
              show: true
            },
            axisTicks: {
              color: theme.palette.divider,
              show: true
            },
            labels: {
              offsetY: 1,
              style: {
                fontSize: "14px",
                fontFamily: theme.typography.fontFamily,
                colors: hexToRgba(theme.palette.primary.main, .7),
                fontWeight: 500
              }
            },
            categories: sortedObjectives.map((item) => item.title)
          },
          states: {
            hover: {
              filter: {
                type: "lighten"
              }
            },
            active: {
              allowMultipleDataPointsSelection: false,
              filter: {
                type: "none"
              }
            }
          }
        },
        series: [{
          data: sortedObjectives.map((item) => item.score),
          name: "Result"
        }]
      }
    }
    return null
  }, [objectives])

  const link = useMemo(() => {
    return isPractice ? "https://knowledge-pillars.com/practice-tests/" : "https://knowledge-pillars.com/certifications/"
  }, [isPractice])

  const actions: ActionItem[] = useMemo(() => {
    return [
      {
        key: "downloadSVG",
        label: "Download SVG",
        onClick: async () => {
          const chartInstance = ApexCharts.getChartByID("objective-results-chart")
          chartInstance && chartInstance.exports.exportToSVG()
        }
      },
      {
        key: "downloadPNG",
        label: "Download PNG",
        onClick: async () => {
          const chartInstance = ApexCharts.getChartByID("objective-results-chart")
          chartInstance && chartInstance.exports.exportToPng()
        }
      },
      {
        key: "downloadCSV",
        label: "Download CSV",
        onClick: async () => {
          const chartInstance = ApexCharts.getChartByID("objective-results-chart")
          chartInstance && chartInstance.exports.exportToCSV({
            series: (chartInstance as any).w.config.series
          })
        }
      }
    ]
  }, [])

  return (
    <Box p={3} bgcolor="white.main" borderRadius="20px">
      {chart && (
        <>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h5" fontWeight={900}>
              Objective Results
            </Typography>
            <ActionsMenu
              options={{
                label: "Download Stats",
                color: "lightBlue",
                icon: <FileDownloadIcon/>,
                variant: "square"
              }}
              actions={actions}
            />
          </Box>
          <Divider sx={{my: 3}}/>
          <Paper sx={{
            px: 3,
            bgcolor: (theme) => hexToRgba(theme.palette.primary.main, .03),
            borderRadius: "20px",
            "& .apexcharts-gridlines-horizontal": {
              transform: "translateY(-20px)"
            },
            "& .apexcharts-grid-borders > .apexcharts-gridline": {
              transform: "translateY(-20px)"
            },
            "& .apexcharts-grid-borders > .apexcharts-gridline:first-of-type": {
              display: "none"
            },
            "& .apexcharts-grid-borders > line:last-of-type": {
              display: "none"
            },
            "& .apexcharts-tooltip": {
              "& > div:first-of-type": {
                backgroundColor: hexToRgba(theme.palette.primary.main, .1),
                textAlign: "center",
                px: 2,
                py: .5,
                mb: 0,
                fontWeight: 500
              }
            },
            "& .apexcharts-tooltip-series-group": {
              flexDirection: "row-reverse",
              justifyContent: "center",
              px: 1.5,
              py: "8px !important"
            },
            "& .apexcharts-tooltip-text": {
              flex: 1
            },
            "& .apexcharts-tooltip-y-group": {
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              gap: 2,
              p: 0
            },
            "& .apexcharts-tooltip-text-y-label": {
              fontWeight: 500
            },
            "& .apexcharts-tooltip-text-y-value": {
              fontWeight: 700
            },
            "& .apexcharts-yaxis-annotations > line": {
              transform: "rotate(-90deg)"
            }
          }}>
            <Chart
              height={(itemHeight * objectives.length) + 71}
              width="100%"
              type="bar"
              {...chart}
            />
          </Paper>
          <Divider sx={{my: 3}}/>
        </>
      )}
      <Box>
        <Typography variant="h6" fontWeight={900} mb={1.5}>
          What do these results mean?
        </Typography>
        <Typography>
          {isPassed ? (
            <>
              <b>Congratulations!</b> You have passed the
            </>
          ) : "Unfortunately you didn't pass the"}
          {" " + examName} ({examCode}) {isPractice ? "Practice Test." : "Certification Exam."}<br/>
          The information shown above indicates your relative performance on each objective of this {isPractice ? "test" : "exam"}.<br/>
          We hope this information will help you determine areas, if any, for further skills development.<br/>
          For a complete list of skills measured for each objective please visit the {isPractice ? "test" : "exam"} preparation page {" "}
          <Link
            component={RouterLink}
            to={link}
            sx={{textDecoration: "underline"}}
            color="secondary">
            {link}
          </Link>
        </Typography>
      </Box>
    </Box>
  )
}
