import {Box} from "@mui/material"
import React, {useMemo, useState} from "react"
import {Outlet, useParams} from "react-router-dom"
import Header from "components/Header"
import Footer from "components/Footer"
import Sidebar from "components/Sidebar"
import {useSelector} from "store"
import {sidebarWidth} from "consts"

interface Props {
  noSidebar?: boolean
  type?: "district" | "admin"
}

export default function MainLayout({
  noSidebar,
  type,
  children
}: React.PropsWithChildren<Props>) {
  const params = useParams()

  const {currentSchool} = useSelector(({schoolSlice}) => schoolSlice)

  const [showMobileSidebar, setShowMobileSidebar] = useState(false)

  const checkExamWindowPage = useMemo(() => {
    return !!((params.examCode && params.sessionId) || params.labSessionId)
  }, [params])

  return (
    <Box
      key={currentSchool}
      position="relative"
      width="100%"
      height="auto"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      sx={{
        minHeight: "100vh"
      }}>
      {!checkExamWindowPage && (
        <Header onSidebarMobileOpen={() => setShowMobileSidebar(true)}/>
      )}
      <Box display="flex" height="100%" flexGrow={1} flexBasis={0} overflow={checkExamWindowPage ? "hidden" : undefined}>
        {(!noSidebar && !checkExamWindowPage) && (
          <Sidebar
            onMobileClose={() => setShowMobileSidebar(false)}
            openMobile={showMobileSidebar}
            type={type}
          />
        )}
        <Box
          position="relative"
          height="100%"
          sx={{
            width: noSidebar || checkExamWindowPage ? "100%" : {xs: "100%", lg: `calc(100% - ${sidebarWidth}px)`}
          }}>
          <Box position="relative" width="100%" height="100%">
            {children || <Outlet/>}
          </Box>
        </Box>
      </Box>
      {!checkExamWindowPage && <Footer/>}
    </Box>
  )
}
