import {MoreVert} from "@mui/icons-material"
import React, {useEffect, useRef, useState} from "react"
import {
  Box, Button, ClickAwayListener, Grow,
  MenuItem, MenuList, Paper, Popper
} from "@mui/material"
import {ButtonOwnProps} from "@mui/material/Button"
import hexToRgba from "../utils/hexToRgba"

export type ActionItem = {
  key: string
  label: string
  onClick: () => void
  icon?: React.ReactNode
  disabled?: boolean
}

type Options = {
  label: string
  icon?: React.ReactNode
  variant?: ButtonOwnProps["variant"]
  size?: ButtonOwnProps["size"]
  color?: ButtonOwnProps["color"]
}

interface Props {
  actions: ActionItem[]
  options?: Options
}

export default function ActionsMenu({
  actions,
  options
}: Props) {
  const [open, setOpen] = useState(false)

  const anchorRef = useRef(null)
  const prevOpen = useRef(open)

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus()
    }

    prevOpen.current = open
  }, [open])

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }

    setOpen(false)
  }

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault()
      setOpen(false)
    } else if (event.key === "Escape") {
      setOpen(false)
    }
  }

  const handleClick = (action: ActionItem["onClick"]) => {
    action()
    setOpen(false)
  }

  return (
    <Box position="relative" zIndex={2}>
      <Button
        ref={anchorRef}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        sx={{minWidth: "unset"}}
        onClick={handleToggle}
        startIcon={options?.icon}
        variant={options.variant}
        size={options?.size}
        color={options?.color}>
        {options.label || <MoreVert/>}
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        placement="bottom"
        sx={{zIndex: 1, mt: "4px !important"}}
        transition>
        {({TransitionProps, placement}) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === "bottom-start" ? "left top" : "left bottom"
            }}>
            <Paper elevation={0} sx={{
              borderRadius: "8px",
              bgcolor: "white.main",
              border: (theme) => `1px solid ${hexToRgba(theme.palette.primary.main, .4)}`
            }}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  sx={{
                    p: 1
                  }}
                  autoFocusItem={open}
                  onKeyDown={handleListKeyDown}>
                  {actions.map(i => (
                    <MenuItem
                      key={i.key}
                      sx={{p: 0}}
                      onClick={() => handleClick(i.onClick)}
                      disabled={i.disabled}
                      disableGutters>
                      <Button
                        startIcon={i?.icon}
                        sx={{border: "none"}}
                        variant="outlinedSquare"
                        size="small">
                        {i.label}
                      </Button>
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  )
}
