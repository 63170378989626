import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import StudentVouchersList from "components/StudentVouchersList"
import {LINKS} from "consts/links"
import React, {useEffect, useMemo, useState} from "react"
import {useLazyQuery} from "@apollo/client"
import LayoutWithBreadcrumbs from "components/LayoutWithBreadcrumbs"
import {
  ExamSession,
  GetTestingSessionDetailsQuery,
  GetTestingSessionDetailsQueryVariables,
  ResultObjective,
  TestingSession
} from "generated/graphql"
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Link,
  Paper,
  Typography
} from "@mui/material"
import {useDispatch} from "react-redux"
import Info from "pages/SessionDetails/components/Info"
import ObjectiveResults from "pages/SessionDetails/components/ObjectiveResults"
import {Link as RouterLink, useNavigate, useParams} from "react-router-dom"
import useMainPageLink from "hooks/useMainPageLink"
import GET_TESTING_SESSION_DETAILS from "api/apollo/queries/GET_TESTING_SESSION_DETAILS"
import {handleError} from "store/slices/notifier/notifier"
import TaskResults from "./components/TaskResults"

interface Props {
  isPractice?: boolean
  breadcrumbs: {text: string, path?: "VAR" | string}[]
  isDistrict?: boolean
}

export default function SessionDetails({
  isPractice,
  isDistrict,
  breadcrumbs
}: Props) {
  const dispatch = useDispatch()
  const params = useParams()
  const navigate = useNavigate()
  const {mainPageLink, isStudent} = useMainPageLink()

  const [session, setSession] = useState<DeepPartial<TestingSession | ExamSession> | null>(null)
  const [objectives, setObjectives] = useState<ResultObjective[]>([])

  const [testingSessionQueryFetch, testingSessionQuery] = useLazyQuery<
    GetTestingSessionDetailsQuery,
    GetTestingSessionDetailsQueryVariables
  >(GET_TESTING_SESSION_DETAILS)

  const loading = useMemo(() => {
    return testingSessionQuery.loading || false
  }, [testingSessionQuery.loading])

  const pageTitle = useMemo(() => {
    return isPractice ? "Practice Test Details" : "Exam Details"
  }, [isPractice])

  // const session = useSelector((state) => {
  //   if (params.id) {
  //     if (isPractice) {
  //       const loading = state.practiceSession.loading
  //       const curr = state.practiceSession.currentSession
  //       const {
  //         result,
  //         examCode,
  //         userName,
  //         examName,
  //         finishDate,
  //         status,
  //         displayCode,
  //         options
  //       } = curr || {}
  //
  //       const {score, isPassed, objectives} = result || {}
  //
  //       return {
  //         examCode,
  //         displayCode,
  //         score,
  //         isPassed,
  //         objectives,
  //         userName,
  //         examName,
  //         finishDate,
  //         loading,
  //         status,
  //         showAnswers: options?.showResult
  //       }
  //     } else {
  //       const loading = state.examsSlice.loading
  //       const curr = state.examsSlice.currentExamDetails
  //       const {
  //         score,
  //         isPassed,
  //         objectives,
  //         userName,
  //         examName,
  //         finishDate,
  //         examCode,
  //         displayCode
  //       } = curr || {}
  //
  //       return {
  //         examCode,
  //         displayCode,
  //         score,
  //         isPassed,
  //         objectives,
  //         userName,
  //         examName,
  //         finishDate,
  //         loading,
  //         showAnswers: undefined
  //       }
  //     }
  //   }
  // })
  // const currentSession = useSelector(state => state.practiceSession.currentSession)
  // const taskAnswers = useSelector(state => state.practiceSession.tasksAnswers)

  // const [examSessionQueryFetch, examSessionQuery] = useLazyQuery<
  //   GetExamSessionQuery,
  //   GetExamSessionQueryVariables
  // >(GET_EXAM_SESSION, {
  //   fetchPolicy: "network-only"
  // })

  // const examSession = useMemo(() => {
  //   return examSessionQuery.data?.getExamSession || null
  // }, [examSessionQuery.data])

  // useEffect(() => {
  //   return () => {
  //     dispatch(resetSessionInfo())
  //     dispatch(clearCurrentExamDetails())
  //   }
  // }, [])

  // const serializedObjectives = useMemo(() => {
  //   if (!!session?.objectives?.length) {
  //     return session.objectives.map((obj) => {
  //       return {...obj, color: obj.score > 74 ? "#1ddc47" : "#ea4545"}
  //     })
  //   } else {
  //     return []
  //   }
  // }, [session])

  // const serializedTasks: Array<ICorrectAnswers> = useMemo(() => {
  //   if (!isPractice) {
  //     return null
  //   }
  //
  //   const tasks = currentSession?.tasks
  //
  //   if (!tasks?.length) {
  //     return []
  //   }
  //
  //   return tasks.reduce((acc, item) => {
  //     const {id, submitted, validated, task, correctAnswer} = item || {}
  //     const {description, displayName, type, multipleDetails} = task || {}
  //     const codeSnippet = multipleDetails?.codeSnippet || null
  //     const answers = taskAnswers?.filter(i => !!i.answered).find(i => i.taskId === id)?.answers || undefined
  //
  //     if (!displayName) return acc
  //
  //     const taskObject = {
  //       id,
  //       submitted,
  //       validated,
  //       correctAnswer,
  //       description,
  //       displayName,
  //       type,
  //       multipleDetails,
  //       codeSnippet,
  //       answers
  //     }
  //
  //     acc.push(taskObject)
  //
  //     return acc
  //   }, [])
  // }, [currentSession?.tasks, taskAnswers, isPractice])

  const breadcrumbsPathBack = useMemo(() => {
    if (isStudent) {
      return isPractice ? "/practice-results" : "/my-results"
    }

    return isPractice ? "/student-practice-results" : "/student-results"
  }, [isStudent, isPractice])

  const title = useMemo(() => {
    if (session) {
      const name = loading || !session ? "Loading..." : session?.exam?.displayName?.value || "Unknown"
      const code = session?.exam?.displayCode?.toUpperCase() || ""

      return name + ((loading || !code) ? "" : ` (${code})${isPractice ? " - Practice Test" : ""}`)
    } else {
      return ""
    }
  }, [isPractice, session, loading])

  useEffect(() => {
    // const initPage = async () => {
    //   if (!!params?.id) {
    //     if (!isPractice) {
    //       try {
    //         await examSessionQueryFetch({
    //           variables: {
    //             examSessionId: params.id
    //           }
    //         })
    //
    //         const wrappedDetails = await dispatch(fetchExamDetailsByIdThunk(params.id))
    //
    //         unwrapResult(wrappedDetails as any)
    //       } catch (err) {
    //         console.warn(err)
    //         // navigate(isDistrict ? LINKS.districtAdmin : mainPageLink)
    //       }
    //     } else {
    //       try {
    //         const wrappedDetails = await dispatch(fetchSessionExamDetails({sessionId: params.id}))
    //         const wrappedAnswers = await dispatch(getTasksAnswers(params.id))
    //
    //         unwrapResult(wrappedDetails as any)
    //         unwrapResult(wrappedAnswers as any)
    //       } catch (err) {
    //         console.warn(err)
    //         // navigate(isDistrict ? LINKS.districtAdmin : mainPageLink)
    //       }
    //     }
    //   }
    // }
    //
    // initPage()
    const sessionId = params?.id

    if (sessionId) {
      if (isPractice) {
        testingSessionQueryFetch({
          variables: {
            testingSessionId: sessionId
          }
        }).then(res => {
          setSession(res.data?.getTestingSession || null)
          setObjectives(res.data?.getTestingSession?.result?.objectives || [])
        }).catch(err => {
          dispatch(handleError(err))
        })
      } else {
        // data?.progress?.map(i => ({
        //   objectiveId: i?.objective?._id,
        //   title: i?.objective?.title.value,
        //   score: i?.averageObjectiveScore
        // }))
      }
    }
  }, [params?.id, isPractice])

  return (
    <LayoutWithBreadcrumbs
      card={false}
      helmetTitle={`${pageTitle} | KP Platform`}
      title={pageTitle}
      breadcrumbs={[...breadcrumbs.map(i => {
        if (i.path === "VAR") {
          return {
            ...i,
            path: breadcrumbsPathBack
          }
        } else {
          return i
        }
      }), {
        text: title
      }]}>
      {(loading || !session) ? (
        <Box display="flex" justifyContent="center" alignItems="center" py={32}>
          <CircularProgress/>
        </Box>
      ) : (
        <Box sx={{
          display: "flex",
          flexDirection: "column"
        }}>
          <Paper
            sx={{
              p: 4,
              display: "flex",
              flexDirection: "column",
              gap: 2.5
            }}>
            <Info
              isPractice={isPractice}
              data={session}
            />
            <ObjectiveResults
              objectives={objectives}
              isPassed={session._isPassed}
              isPractice={isPractice}
              examCode={session.exam.code}
              examName={session.exam.displayName.value}
            />
            <TaskResults
              tasks={session._tasks}
              givenAnswers={session.givenAnswers}
            />
          </Paper>
          <Paper
            sx={{
              mt: 4,
              p: 4,
              display: "flex",
              flexDirection: "column",
              gap: 2.5
            }}>
            <Box>
              <Typography variant="h5" fontWeight={900}>
                Available tests
              </Typography>
              <Divider sx={{mt: 4, mb: 2}}/>
              <Box>
                <StudentVouchersList compact/>
              </Box>
              <Divider sx={{my: 4}}/>
              <Box>
                <Link to={LINKS.dashboard} component={RouterLink} underline="none">
                  <Button
                    variant="outlined"
                    startIcon={<ArrowBackIcon/>}
                    sx={{px: 2}}>
                    BACK TO DASHBOARD
                  </Button>
                </Link>
              </Box>
            </Box>
          </Paper>
        </Box>
      )}
    </LayoutWithBreadcrumbs>
  )
}
