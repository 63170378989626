import {differenceInSeconds} from "date-fns"

export default function getSessionDuration(startDate: string, finishDate: string) {
  const totalSeconds = differenceInSeconds(new Date(finishDate), new Date(startDate))

  if (totalSeconds < 0) return "00:00:00"

  const hours = Math.floor(totalSeconds / 3600)
  const minutes = Math.floor((totalSeconds % 3600) / 60)
  const seconds = totalSeconds % 60

  const formatNumber = (num: number) => String(num).padStart(2, "0")

  return `${formatNumber(hours)}:${formatNumber(minutes)}:${formatNumber(seconds)}`
}
