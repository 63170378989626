import {Box, Typography} from "@mui/material"
import {useTheme} from "@mui/styles"
import {PropsWithChildren, useEffect, useMemo, useState} from "react"

interface Props {
  size?: number
  passingScore: number
  value: number
}

export default function SessionResultProgress({
  size = 240,
  passingScore,
  value
}: PropsWithChildren<Props>) {
  const theme = useTheme()

  const [animatedValue, setAnimatedValue] = useState(0)
  const radius = (size - 20) / 2
  const circumference = 2 * Math.PI * radius
  const progress = (animatedValue / 100) * circumference
  const strokeWidht = size / 12

  const isPassed = useMemo(() => {
    return value >= passingScore
  }, [passingScore, value])

  useEffect(() => {
    let currentValue = 0
    const duration = 1000
    const intervalTime = 10
    const step = value / (duration / intervalTime)

    const interval = setInterval(() => {
      currentValue += step

      if (currentValue >= value) {
        currentValue = value
        clearInterval(interval)
      }

      setAnimatedValue(currentValue)
    }, intervalTime)

    return () => clearInterval(interval)
  }, [value])

  return (
    <Box position="relative" width={size} height={size}>
      <svg width="100%" height="100%">
        <circle
          cx={size / 2}
          cy={size / 2}
          r={radius}
          fill="none"
          stroke="rgba(0, 40, 85, 0.1)"
          strokeWidth={strokeWidht}
        />
        <circle
          cx={size / 2}
          cy={size / 2}
          r={radius}
          fill="none"
          stroke={isPassed ? theme.palette.success.main : theme.palette.error.main}
          strokeWidth={strokeWidht}
          strokeDasharray={circumference}
          strokeDashoffset={circumference - progress}
          strokeLinecap="round"
          style={{
            transition: "stroke-dashoffset .3s ease"
          }}
        />
      </svg>
      <Box
        position="absolute"
        top="50%"
        left="50%"
        textAlign="center"
        sx={{
          transform: "translate(-50%, -50%)"
        }}>
        <Typography variant="body1" color="grey" fontSize={size / 15}>
          {passingScore}% to pass
        </Typography>
        <Typography variant="h3" my={.5} fontWeight={600} sx={{fontSize: `${size / 5.5}px !important`}}>
          {animatedValue.toFixed(1)}%
        </Typography>
        <Typography variant="body1" color="grey" fontSize={size / 15}>
          Your score
        </Typography>
      </Box>
    </Box>
  )
}
