import React, {useEffect, useRef, useState} from "react"
import {Box, Collapse, Typography, useTheme} from "@mui/material"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"

interface Props {
  text: string
  maxLines?: number
}

export default function ViewMoreText({text, maxLines = 2}: Props) {
  const theme = useTheme()

  const [showMore, setShowMore] = useState(false)
  const [showButton, setShowButton] = useState<boolean>(false)
  const textRef = useRef<HTMLDivElement | null>(null)

  const handleToggle = () => {
    setShowMore(!showMore)
  }

  useEffect(() => {
    if (textRef.current) {
      const isOverflowing = textRef.current.scrollHeight > textRef.current.clientHeight
      setShowButton(isOverflowing)
    }
  }, [text])

  return (
    <Box>
      <Collapse in={showMore}>
        <Typography gutterBottom>
          {text}
        </Typography>
      </Collapse>
      <Collapse in={!showMore}>
        <Typography
          ref={textRef}
          sx={{
            display: "-webkit-box",
            WebkitLineClamp: maxLines,
            WebkitBoxOrient: "vertical",
            overflow: "hidden"
          }}>
          {text}
        </Typography>
      </Collapse>
      {showButton && (
        <Box mt={1} sx={{cursor: "pointer"}} display="flex" alignItems="center" onClick={handleToggle}>
          <Typography>
            {showMore ? "View Less" : "View More"}
          </Typography>
          <ExpandMoreIcon
            sx={{
              transform: showMore ? "rotate(180deg)" : "rotate(0deg)",
              transition: theme.transitions.create("transform", {
                duration: theme.transitions.duration.shortest
              })
            }}
          />
        </Box>
      )}
    </Box>
  )
}
