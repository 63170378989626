import React from "react"
import {Box, Grid} from "@mui/material"
import Plus from "assets/icons/Plus"
import {useNavigate} from "react-router"
import {LINKS} from "consts/links"
import hexToRgba from "utils/hexToRgba"
import {kpSecondaryColors} from "consts"

export default function AddExamItem() {
  const navigate = useNavigate()

  const onAddExam = () => {
    navigate(`/practice-tests${LINKS.addExamByVoucher}`)
  }

  return (
    <Grid item xs={12} sm={6} md={4}>
      <Box
        onClick={onAddExam}
        sx={{
          backgroundColor: (theme) => theme.palette.white.main,
          height: "100%",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
          borderRadius: "20px",
          transition: ".3s",
          "&:hover": {
            backgroundColor: hexToRgba(kpSecondaryColors.lightBlue, .3),
            transition: ".3s"
          }
        }}>
        <Plus
          sx={{
            color: (theme) => theme.palette.primary.main,
            width: "35%",
            height: "35%"
          }}
        />
      </Box>
    </Grid>
  )
}
