import {useLazyQuery, useMutation} from "@apollo/client"
import {Box, Button, Link, Paper, Switch, Typography} from "@mui/material"
import ADD_ADMIN_TO_DISTRICT from "api/apollo/mutations/ADD_ADMIN_TO_DISTRICT"
import ADD_STAFF_TO_SCHOOL from "api/apollo/mutations/ADD_STAFF_TO_SCHOOL"
import DELETE_ADMIN_FROM_SCHOOL from "api/apollo/mutations/DELETE_ADMIN_FROM_SCHOOL"
import DELETE_STAFF_FROM_SCHOOL from "api/apollo/mutations/DELETE_STAFF_FROM_SCHOOL"
import REMOVE_ADMIN_FROM_DISTRICT from "api/apollo/mutations/REMOVE_ADMIN_FROM_DISTRICT"
import GET_USER_DISTRICTS from "api/apollo/queries/GET_USER_DISTRICTS"
import GET_USER_SCHOOLS from "api/apollo/queries/GET_USER_SCHOOLS"
import Plus from "assets/icons/Plus"
import AddPersonnelForm from "components/AddPersonnelForm"
import ConfirmationAlert from "components/ConfirmationAlert"
import CustomModal from "components/CustomModal"
import DataTable from "components/DataTable"
import {
  DataTableActionButtons,
  DataTableQueryUpdate,
  DataTableSchema,
  DataTableState
} from "components/DataTable/types.t"
import {LINKS} from "consts/links"
import {
  AddAdminToDistrictMutation,
  AddAdminToDistrictMutationVariables,
  AddStaffToSchoolMutation,
  AddStaffToSchoolMutationVariables,
  DeleteAdminFromSchoolMutation,
  DeleteAdminFromSchoolMutationVariables,
  DeleteStaffFromSchoolMutation,
  DeleteStaffFromSchoolMutationVariables,
  District,
  GetUserDistrictsQuery,
  GetUserDistrictsQueryVariables,
  GetUserSchoolsQuery,
  GetUserSchoolsQueryVariables,
  RemoveAdminFromDistrictMutation,
  RemoveAdminFromDistrictMutationVariables, School, User
} from "generated/graphql"
import {Link as RouterLink} from "react-router-dom"
import AddDistrictAdminForm from "./components/AddDistrictAdminForm"
import React, {useMemo, useState} from "react"
import {useDispatch} from "react-redux"
import {handleError} from "store/slices/notifier/notifier"

type ModalTypes =
  "ADD_TEACHER" |
  "ADD_SCHOOL_ADMIN_ACCESS" |
  "REMOVE_SCHOOL_ADMIN_ACCESS" |
  "DELETE_TEACHER" |
  "ADD_DISTRICT_ADMIN" |
  "ADD_DISTRICT_ADMIN_ACCESS" |
  "DELETE_DISTRICT_ADMIN"

interface Props {
  teacher: Partial<User>
  districtId?: string
  isDistrictView?: boolean
}

export default function AccessLevels({
  teacher,
  districtId
}: Props) {
  const dispatch = useDispatch()

  const [modal, setModal] = useState<ModalTypes | null>(null)
  const [schoolsStateQuery, setSchoolsStateQuery] = useState<DataTableState | null>(null)
  const [districtsStateQuery, setDistrictsStateQuery] = useState<DataTableState | null>(null)
  const [selectedSchoolId, setSelectedSchoolId] = useState<string | null>(null)
  const [selectedDistrictId, setSelectedDistrictId] = useState<string | null>(null)
  const [schoolsData, setSchoolsData] = useState<
    Partial<School & {isAdmin?: boolean}>[] | null
  >(null)
  const [districtsData, setDistrictsData] = useState<
    Partial<District>[] | null
  >(null)

  const [schoolsQueryFetch, schoolsQuery] = useLazyQuery<
    GetUserSchoolsQuery,
    GetUserSchoolsQueryVariables
  >(GET_USER_SCHOOLS)

  const [districtsQueryFetch, districtsQuery] = useLazyQuery<
    GetUserDistrictsQuery,
    GetUserDistrictsQueryVariables
  >(GET_USER_DISTRICTS)

  const [addStaffToSchool] = useMutation<
    AddStaffToSchoolMutation,
    AddStaffToSchoolMutationVariables
  >(ADD_STAFF_TO_SCHOOL)

  const [deleteAdminFromSchool] = useMutation<
    DeleteAdminFromSchoolMutation,
    DeleteAdminFromSchoolMutationVariables
  >(DELETE_ADMIN_FROM_SCHOOL)

  const [deleteStaffFromSchool] = useMutation<
    DeleteStaffFromSchoolMutation,
    DeleteStaffFromSchoolMutationVariables
  >(DELETE_STAFF_FROM_SCHOOL)

  const [removeAdminFromDistrict] = useMutation<
    RemoveAdminFromDistrictMutation,
    RemoveAdminFromDistrictMutationVariables
  >(REMOVE_ADMIN_FROM_DISTRICT)

  const [addAdminToDistrict] = useMutation<
    AddAdminToDistrictMutation,
    AddAdminToDistrictMutationVariables
  >(ADD_ADMIN_TO_DISTRICT)

  const districtsTableSchema: DataTableSchema<District> = useMemo(() => {
    return [
      {
        type: "text",
        headerText: "District Name",
        headerNoWrap: true,
        contentWrap: "nowrap",
        fieldName: "name"
      },
      {
        type: "custom",
        headerText: "District Admin",
        headerNoWrap: true,
        content: () => "Yes"
      },
      {
        type: "custom",
        headerText: "",
        headerNoWrap: true,
        contentWrap: "nowrap",
        content: data => (
          <Button
            variant="outlined"
            color="error"
            size="small"
            onClick={() => {
              setSelectedDistrictId(data._id)
              setModal("DELETE_DISTRICT_ADMIN")
            }}>
            Remove
          </Button>
        )
      }
    ]
  }, [districtsData])

  const handleQueryDistricts: DataTableQueryUpdate<{
    refetch?: boolean
  }> = (state, options) => {
    setDistrictsStateQuery(state)
    const fetchPolicy = options?.refetch ? "network-only" : undefined

    districtsQueryFetch({
      fetchPolicy,
      variables: {
        userId: teacher._id
      }
    }).then(res => {
      if (res.data?.getUser) {
        const {adminInDistricts} = res.data.getUser

        setDistrictsData((districtId ? (
          adminInDistricts?.filter(i => i._id === districtId)
        ) : adminInDistricts) || null)
      }
    })
  }

  const districtsTableActionButtons: DataTableActionButtons = useMemo(() => {
    return [
      {
        key: "add",
        label: "Add",
        variant: "outlined",
        color: "success",
        icon: <Plus/>,
        disabled: districtId && !!districtsData?.length,
        onClick: () =>
          setModal(districtId ?
            "ADD_DISTRICT_ADMIN_ACCESS"
            :
            "ADD_DISTRICT_ADMIN"
          )
      }
    ]
  }, [districtId, districtsData])

  const schoolsTableSchema: DataTableSchema<
    School & {isAdmin: boolean}
  > = useMemo(() => {
    return [
      {
        type: "custom",
        headerText: "District Name",
        headerNoWrap: true,
        contentWrap: "nowrap",
        content: data => {
          if (data?.district?.name) {
            return (
              <Link
                to={`${LINKS.adminDistricts}/${data.district._id}`}
                component={RouterLink}
                target="_blank"
                underline="always">
                {data.district.name}
              </Link>
            )
          }

          return null
        }
      },
      {
        type: "custom",
        headerText: "School Name",
        headerNoWrap: true,
        contentWrap: "nowrap",
        content: data => (
          <Link
            to={`${LINKS.adminSchools}/${data._id}`}
            component={RouterLink}
            target="_blank"
            underline="always">
            {data.name}
          </Link>
        )
      },
      {
        type: "text",
        headerText: "State",
        headerNoWrap: true,
        contentWrap: "nowrap",
        fieldName: "state"
      },
      {
        type: "custom",
        headerText: "Admin Access",
        headerNoWrap: true,
        content: data => {
          const isAdmin = data.isAdmin || false

          return (
            <Box display="flex" alignItems="center" gap={1.5}>
              {isAdmin ? "Yes" : "No"}
              <Switch
                size="small"
                edge="start"
                color={isAdmin ? "success" : "error"}
                checked={isAdmin}
                onChange={e => {
                  e.preventDefault()
                  setSelectedSchoolId(data._id)
                  handleSetAdminRights(e.target.checked)
                }}
                inputProps={{"aria-label": "controlled"}}
              />
            </Box>
          )
        }
      },
      {
        type: "custom",
        headerText: "",
        headerNoWrap: true,
        contentWrap: "nowrap",
        content: data => (
          <Button
            variant="outlined"
            color="error"
            size="small"
            onClick={() => {
              setSelectedSchoolId(data._id)
              setModal("DELETE_TEACHER")
            }}>
            Remove
          </Button>
        )
      }
    ]
  }, [schoolsData])

  const handleQuerySchools: DataTableQueryUpdate<{
    refetch?: boolean
  }> = (state, options) => {
    setSchoolsStateQuery(state)
    const fetchPolicy = options?.refetch ? "network-only" : undefined

    schoolsQueryFetch({
      fetchPolicy,
      variables: {
        userId: teacher._id
      }
    }).then(res => {
      if (res.data?.getUser) {
        const {adminInSchools, teacherInSchools} = res.data.getUser

        const filteredAdminInSchools = adminInSchools?.filter(i => {
          return districtId ? (
            i?.district?._id === districtId
          ) : true
        }) || []

        const filteredSchools = teacherInSchools?.filter(i => {
          return districtId ? (
            i?.district?._id === districtId
          ) : true
        }) || []

        setSchoolsData([
          ...filteredAdminInSchools?.map(i => {
            return {
              ...i,
              isAdmin: true
            }
          }),
          ...filteredSchools?.filter(i => {
            return filteredAdminInSchools.length ? (
              !filteredAdminInSchools.some(school => school._id === i._id)
            ) : true
          })
        ] || null)
      }
    })
  }

  const schoolsTableActionButtons: DataTableActionButtons = useMemo(() => {
    return [
      {
        key: "add",
        label: "Add",
        variant: "outlined",
        color: "success",
        icon: <Plus/>,
        onClick: () => setModal("ADD_TEACHER")
      }
    ]
  }, [])

  const handleCancelModal = (refetch?: "districts" | "schools") => {
    setModal(null)
    setSelectedSchoolId(null)
    setSelectedDistrictId(null)
    if (refetch === "districts") {
      handleQueryDistricts(districtsStateQuery, {refetch: true})
    }
    if (refetch === "schools") {
      handleQuerySchools(schoolsStateQuery, {refetch: true})
    }
  }

  const handleSetAdminRights = (
    value: boolean,
    confirmed: boolean = false,
    isDistrict?: boolean
  ) => {
    if (isDistrict) {
      addAdminToDistrict({
        variables: {
          districtId,
          userEmail: teacher.email
        }
      }).then(() => {
        handleQueryDistricts(districtsStateQuery, {refetch: true})
      }).catch(err => {
        dispatch(handleError(err))
      })
    } else {
      if (value) {
        if (!confirmed) {
          setModal("ADD_SCHOOL_ADMIN_ACCESS")
        } else {
          addStaffToSchool({
            variables: {
              schoolId: selectedSchoolId,
              userEmail: teacher.email,
              isTeacher: true,
              isAdmin: true
            }
          }).then(() => {
            handleQuerySchools(schoolsStateQuery, {refetch: true})
          }).catch(err => {
            dispatch(handleError(err))
          })
        }
      } else {
        if (!confirmed) {
          setModal("REMOVE_SCHOOL_ADMIN_ACCESS")
        } else {
          deleteAdminFromSchool({
            variables: {
              schoolId: selectedSchoolId,
              userId: teacher._id
            }
          }).then(() => {
            handleQuerySchools(schoolsStateQuery, {refetch: true})
          }).catch(err => {
            dispatch(handleError(err))
          })
        }
      }
    }
  }

  const handleDelete = () => {
    if (selectedSchoolId) {
      deleteStaffFromSchool({
        variables: {
          schoolId: selectedSchoolId,
          userId: teacher._id
        }
      }).then(() => {
        handleQuerySchools(schoolsStateQuery, {refetch: true})
      }).catch((err) => {
        dispatch(handleError(err))
      })
    }
    if (selectedDistrictId) {
      removeAdminFromDistrict({
        variables: {
          districtId: selectedDistrictId,
          userId: teacher._id
        }
      }).then(() => {
        handleQueryDistricts(districtsStateQuery, {refetch: true})
      }).catch((err) => {
        dispatch(handleError(err))
      })
    }

    handleCancelModal()
  }

  return (
    <Box>
      <Paper variant="outlined" sx={{p: 2, mb: 4, mt: 2}}>
        <Typography color="textPrimary" variant="h6">
          Districts
        </Typography>
        <DataTable
          schema={districtsTableSchema}
          data={districtsData}
          emptyDataMessage={
            <Box py={5}>
              <Typography variant="h5" textAlign="center" fontWeight="normal">
                The teacher is not a district administrator
              </Typography>
            </Box>
          }
          loading={districtsQuery.loading}
          onQueryUpdate={handleQueryDistricts}
          actionButtons={districtsTableActionButtons}
        />
      </Paper>
      <Paper variant="outlined" sx={{p: 2, mb: 4, mt: 2}}>
        <Typography color="textPrimary" variant="h6">
          Schools
        </Typography>
        <DataTable
          schema={schoolsTableSchema}
          data={schoolsData}
          emptyDataMessage={
            <Box py={5}>
              <Typography variant="h5" textAlign="center" fontWeight="normal">
                Schools not found
              </Typography>
            </Box>
          }
          loading={schoolsQuery.loading}
          onQueryUpdate={handleQuerySchools}
          actionButtons={schoolsTableActionButtons}
        />
      </Paper>
      <CustomModal
        open={modal === "ADD_TEACHER"}
        onClose={handleCancelModal}>
        <AddPersonnelForm
          userEmail={teacher.email}
          districtId={districtId}
          type="ADD"
          onCancel={handleCancelModal}
          onSubmit={() => handleCancelModal("schools")}
        />
      </CustomModal>
      <CustomModal
        open={modal === "ADD_DISTRICT_ADMIN"}
        onClose={handleCancelModal}>
        <AddDistrictAdminForm
          onCancel={handleCancelModal}
          onSubmit={() => handleCancelModal("districts")}
          email={teacher.email}
        />
      </CustomModal>
      <ConfirmationAlert
        isOpen={["ADD_SCHOOL_ADMIN_ACCESS", "ADD_DISTRICT_ADMIN_ACCESS"].includes(modal)}
        setOpen={() => handleCancelModal()}
        handleConfirm={() =>
          handleSetAdminRights(true, true, modal === "ADD_DISTRICT_ADMIN_ACCESS")
        }
        handleCancel={handleCancelModal}
        dialogTitle="Assign Administrator Access?"
        dialogContentText={`Do you want to assign administrator access to ${teacher?.fullName}?`}
        cancelButton="No"
        confirmButton={{color: "success", text: "Yes"}}
      />
      <ConfirmationAlert
        isOpen={modal === "REMOVE_SCHOOL_ADMIN_ACCESS"}
        setOpen={() => handleCancelModal()}
        handleConfirm={() => handleSetAdminRights(false, true)}
        handleCancel={handleCancelModal}
        dialogTitle="Revoke Administrator Access?"
        dialogContentText={`Do you want to revoke the administrator access for ${teacher.fullName}?`}
        cancelButton="No"
        confirmButton={{color: "error", text: "Yes"}}
      />
      <ConfirmationAlert
        isOpen={["DELETE_DISTRICT_ADMIN", "DELETE_TEACHER"].includes(modal)}
        setOpen={() => handleCancelModal()}
        handleConfirm={() => handleDelete()}
        handleCancel={handleCancelModal}
        dialogTitle={
          `Are you sure you want to remove ${teacher.fullName} from the ${modal === "DELETE_TEACHER" ? "school" : "district"}?`
        }
        cancelButton="No"
        confirmButton={{color: "error", text: "Yes"}}
      />
    </Box>
  )
}
