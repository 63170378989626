import ExamPassingRateTable from "components/ExamPassingRateTable"
import LayoutWithBreadcrumbs from "components/LayoutWithBreadcrumbs"
import React, {useMemo} from "react"
import {useSelector} from "store"

interface Props {
  type: "admin" | "district"
  breadcrumbs: {text: string, path?: string}[]
}

export default function ExamPassingRate({
  type,
  breadcrumbs
}: Props) {
  const title = useMemo(() => {
    return "Exam Passing Rate"
  }, [])

  const districtId = useSelector((state) => state.districtSlice.currentDistrict)

  return (
    <LayoutWithBreadcrumbs
      helmetTitle={`${title}${type === "admin" ? " - Admin" : ""}`}
      title={title}
      breadcrumbs={[...breadcrumbs.map(i => {
        return {
          ...i
        }
      }), {
        text: title
      }]}>
      <ExamPassingRateTable districtId={districtId} type={type}/>
    </LayoutWithBreadcrumbs>
  )
}
