import {TestingGroup} from "generated/graphql"
import {startCase} from "lodash"
import React from "react"
import makeStyles from "@mui/styles/makeStyles"
import {Box, Button, Link, Typography} from "@mui/material"
import formatDate from "utils/formatDate"
import CopyToClipboard from "./DataTable/components/CopyToClipboard"
import {LINKS} from "../consts/links"
import RotateLeftIcon from "@mui/icons-material/RotateLeft"
import {useTheme} from "@mui/styles"
import TextWithViewMore from "./TextWithViewMore"

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    alignItems: "center",
    "& > img": {
      flexShrink: 0,
      height: 85,
      width: 85,
      objectFit: "contain"
    }
  },
  img: {
    display: "flex",
    width: "48px",
    height: "48px",
    transition: ".3s",
    "&:hover": {
      opacity: .6,
      transition: ".3s"
    }
  }
}))

interface Props {
  examGroup?: Partial<TestingGroup>
  canceledBy?: string
  cancelReason?: string
  createdAt?: string
  displayName?: string
  examCode?: string
  isTesting?: boolean
  duration?: string
  status?: string
  mode?: string
  retake?: boolean
  disableAi?: boolean
  voucherCode?: string
  isCompleted?: boolean
  isPassed?: boolean
  certificateId?: string
  type?: string
  updatedAt?: string
  handleResetCertificate?: () => void
}

export default function ExamCard({
  examGroup,
  createdAt,
  displayName,
  examCode,
  isTesting,
  duration,
  status,
  mode,
  canceledBy,
  cancelReason,
  retake,
  disableAi,
  voucherCode,
  isCompleted,
  isPassed,
  certificateId,
  updatedAt,
  handleResetCertificate,
  type
}: Props) {
  const s = useStyles()
  const theme = useTheme()

  return (
    <Box className={s.root}>
      <img
        alt={displayName || "Exam logo"}
        src={`/${isTesting ? "practiceDetailsIcons" : "examIcons"}/${examCode || "unknown"}.png`}
      />
      <Box ml={2}>
        <Typography color="primary" variant="subtitle2" fontWeight="bold">
          {displayName || "Unknown"}
        </Typography>
        {isTesting && mode && (
         <>
           <Typography whiteSpace="nowrap">
             <span>
               Type: {mode === "Practice Mode" ? "Practice Test" : "Exam Simulator"}
             </span>
           </Typography>
           <Typography whiteSpace="nowrap">
             <span>
               Mode: {mode}
             </span>
           </Typography>
           {disableAi && (
             <Typography whiteSpace="nowrap">
               <span>
                 AI Helper Enabled
               </span>
             </Typography>
           )}
         </>
        )}
        <Typography whiteSpace="nowrap">
          <span>
            {createdAt ? `Date ${formatDate(createdAt, "full-en")}` : "Unknown"}
          </span>
        </Typography>
        {duration && (
          <Typography whiteSpace="nowrap">
            <span>
              Duration {duration}
            </span>
          </Typography>
        )}
        {examGroup?.proctoring && (
          <Typography whiteSpace="nowrap">
            <span>
              Proctoring Mode: {startCase(examGroup.proctoring)}
            </span>
          </Typography>
        )}
        {examGroup?.teacher && (
          <Typography whiteSpace="nowrap">
            <span>
              Teacher: {examGroup.teacher?.fullName}
            </span>
          </Typography>
        )}
        {examGroup?.proctor && (
          <Typography whiteSpace="nowrap">
            <span>
              Proctor: {examGroup.proctor.fullName}
            </span>
          </Typography>
        )}
        {!isTesting && (
          <Typography whiteSpace="nowrap">
            <span>
              Retake: {retake ? "Yes" : "No"}
            </span>
          </Typography>
        )}
        {(!isTesting || !duration) && status && (
          <Typography whiteSpace="nowrap">
            <span>
              Status: {status}
            </span>
          </Typography>
        )}
        {!isTesting && (
          <Typography sx={{display: "flex", alignItems: "center"}}>
            <span>
              Voucher Code: {voucherCode || "N/A"}
            </span>
            {voucherCode && (
              <Box ml={1}>
                <CopyToClipboard value={voucherCode}/>
              </Box>
            )}
          </Typography>
        )}
        <Typography whiteSpace="nowrap" sx={{display: "flex", alignItems: "center"}}>
          <span style={{display: "flex", alignItems: "center"}}>
            {isPassed && !isTesting && certificateId ? "Certificate" : "Passed"}: {!isCompleted ? (
              "N/A"
            ) : (
              <span
                style={{
                  marginLeft: 5,
                  display: "flex",
                  alignItems: "center",
                  color: !isCompleted ? (
                  theme.palette.primary.main
                  ) : (
                  isPassed
                  ) ? (
                  theme.palette.success.main
                  ) : (
                  theme.palette.error.main
                  )}
                }>
                {isPassed && !isTesting && certificateId ? (
                  <Box display="flex" gap={1} alignItems="center">
                    <Link
                      target="_blank"
                      href={`${LINKS.certificate}/${certificateId}`}
                      underline="always"
                      variant="body2"
                      fontSize="1rem">
                      <img
                        alt="certificate"
                        className={s.img}
                        src="/static/blue-certificate.png"
                      />
                    </Link>
                    {type === "examAdmin" && (
                      <Box display="flex" flexDirection="column" gap={1} pr={3} mt={1}>
                        <Button
                          variant="outlined"
                          color="warning"
                          sx={{minWidth: "unset"}}
                          onClick={handleResetCertificate}>
                          <RotateLeftIcon fontSize="medium"/>
                          Regenerate
                        </Button>
                        <Typography variant="body2" color="grey">
                          Date: {formatDate(updatedAt)}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                ) : isPassed ? (
                  "Yes"
                ) : (
                  "No"
                )}
              </span>
            )}
          </span>
        </Typography>
        {cancelReason && (
          <Typography maxWidth={500}>
            <span style={{whiteSpace: "break-spaces", display: "flex"}}>
              Reason: <TextWithViewMore text={cancelReason}/>
            </span>
          </Typography>
        )}
        {canceledBy && (
          <Typography whiteSpace="nowrap">
            <span>
              Canceled By:
            </span>
          </Typography>
        )}
      </Box>
    </Box>
  )
}
