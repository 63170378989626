import {Box, Theme, Typography, useTheme} from "@mui/material"
import {makeStyles} from "@mui/styles"
import React from "react"
import hexToRgba from "../utils/hexToRgba"

const useStyles = makeStyles<Theme, Props>((theme) => ({
  bg: {
    fill: "none",
    stroke: hexToRgba(theme.palette.primary.main, .1),
    strokeWidth: 4
  },
  value: {
    animation: "$progress 1s ease-out forwards",
    fill: "none",
    stroke: ({value}) =>
      value >= 75 ? theme.palette.success.main : theme.palette.error.main,
    strokeWidth: 4
  },
  "@keyframes progress": {
    "0%": {
      strokeDasharray: "0 100"
    }
  }
}))

interface Props {
  value: number
  width?: number | string
  height?: number | string
  showPercentage?: boolean
}

export default function CircularProgress({
  value,
  width,
  height,
  showPercentage = false
}: Props) {
  const s = useStyles({value})
  const theme = useTheme()

  return (
    <Box width={width || 56} height={height || 56} position="relative">
      <svg viewBox="0 0 36 36">
        <path
          d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
          strokeDasharray="100, 100"
          className={s.bg}
        />
        <path
          d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
          strokeDasharray={`${value}, 100`}
          className={s.value}
        />
      </svg>
      {showPercentage && (
        <Box
          sx={{
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate3d(-50%, -50%, 0)",
            display: "flex",
            flexDirection: "column",
            textAlign: "center"
          }}>
          <Typography variant="caption" sx={{opacity: .5}}>
            75% to pass
          </Typography>
          <Typography variant="h5">
            {value}%
          </Typography>
          <Typography variant="caption" sx={{opacity: .5}}>
            Your score
          </Typography>
        </Box>
      )}
    </Box>
  )
}
