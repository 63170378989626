import {Amplify} from "aws-amplify"

const region = process.env.REACT_APP_AWS_REGION || "us-east-1"
const cognitoDomain = process.env.REACT_APP_AWS_COGNITO_DOMAIN || 
  `${process.env.REACT_APP_AWS_COGNITO_USERPOOL_ID.replace("_", "")}.auth.${region}.amazoncognito.com`.toLowerCase()

export const amplifyConfig: Parameters<typeof Amplify.configure>[0] = {
  Auth: {
    Cognito: {
      userPoolId: process.env.REACT_APP_AWS_COGNITO_USERPOOL_ID,
      userPoolClientId:
        process.env.REACT_APP_AWS_COGNITO_USERPOOL_WEB_CLIENT_ID,
      loginWith: {
        oauth: {
          domain: cognitoDomain,
          scopes: ["openid"],
          redirectSignIn: [
            window.location.origin
          ],
          redirectSignOut: [
            window.location.origin
          ],
          responseType: "code"
        }
      }
    }
    
  }
}

export const gaConfig = {
  gtmId: process.env.REACT_APP_GTM_ID
}
