import {Box, Slider, TextField, Typography, useTheme} from "@mui/material"
import React, {useEffect, useMemo, useState} from "react"

interface Props {
  onChange: (value: number) => void
  maxValue?: number
  disabled?: boolean
  label: string
}

export default function RangeSlider({
  onChange,
  maxValue = 0,
  disabled,
  label
}: Props) {
  const theme = useTheme()
  const [value, setValue] = useState<string | number>(0)

  const marks = useMemo(() => ([
    {
      value: maxValue || 0,
      label: `${maxValue || 0}`
    }
  ]), [maxValue])

  const handleSliderChange = (_e: Event, newValue: number | number[]) => {
    setValue(newValue as number)
    onChange(newValue as number)
  }

  const handleBlur = () => {
    const newValue = (value < 0 ? 0 : value > maxValue ? maxValue : value) || 0
    setValue(newValue)

    onChange(newValue as number)
  }

  useEffect(() => {
    setValue(0)
  }, [maxValue])

  return (
    <Box
      px={2}
      py={1}
      border="1px solid"
      borderRadius="12px"
      sx={{
        borderColor: "rgba(0, 0, 0, 0.23)",
        "&:hover": {
          borderColor: (!disabled && !!maxValue) && theme.palette.text.primary
        }
      }}>
      <Typography variant="body1" mb={3}>
        {label}
      </Typography>
      <Box position="relative">
        <Slider
          disabled={!maxValue || disabled}
          step={1}
          value={typeof value === "number" ? value : 0}
          onChange={handleSliderChange}
          valueLabelDisplay="auto"
          min={0}
          max={maxValue}
          sx={{
            color: theme.palette.text.primary,
            height: 6,
            "& .MuiSlider-thumb": {
              width: 14,
              height: 14,
              transition: "0.3s cubic-bezier(.47,1.64,.41,.8)",
              "&:before": {
                boxShadow: "0 2px 12px 0 rgba(0,0,0,0.4)"
              },
              "&:hover, &.Mui-focusVisible": {
                boxShadow: "0px 0px 0px 8px rgb(0 0 0 / 5%)"
              },
              "&.Mui-active": {
                width: 16,
                height: 16
              }
            },
            "& .MuiSlider-valueLabel": {
              fontSize: theme.typography.subtitle2.fontSize,
              fontWeight: theme.typography.subtitle2.fontWeight,
              bottom: 8,
              backgroundColor: "unset",
              color: theme.palette.text.primary,
              "&:before": {
                display: "none"
              },
              "& *": {
                background: "transparent",
                color: theme.palette.text.primary
              }
            },
            "& .MuiSlider-rail": {
              opacity: 0.28
            },
            "& .MuiSlider-track": {
              border: "none"
            },
            "& .MuiSlider-markLabel[data-index=\"0\"]": {
              transform: `translateX(${!maxValue ? "0" : "-100%"})`
            },
            "& .MuiSlider-markLabel": {
              fontWeight: theme.typography.subtitle2.fontWeight,
              minHeight: "20px"
            },
            "& .MuiSlider-mark": {
              display: "none"
            }
          }}
          marks={marks}
        />
        {!disabled && !!maxValue && (
          <Box sx={{
            position: "absolute",
            bottom: "4px"
          }}>
            <TextField
              disabled={!maxValue || disabled}
              value={value}
              size="small"
              onChange={({target}) => {
                setValue(Number(target.value) || "")
              }}
              sx={{
                "& .MuiInputBase-input": {
                  fontWeight: theme.typography.subtitle2.fontWeight,
                  fontSize: theme.typography.subtitle2.fontSize,
                  paddingBottom: "2px",
                  width: "50px"
                }
              }}
              variant="standard"
              onBlur={handleBlur}
              inputProps={{
                step: 1,
                min: 0,
                max: maxValue || 0,
                type: "number"
              }}
            />
          </Box>
        )}
      </Box>
    </Box>
  )
}
