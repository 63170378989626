import {
  Direction,
  Theme,
  createTheme as createMuiTheme,
  responsiveFontSizes
} from "@mui/material"
import createPalette from "@mui/material/styles/createPalette"
import {kpPrimaryColors, kpNeutralsColors, kpSecondaryColors} from "consts"
import {lightShadows} from "./shadows"

interface ThemeConfig {
  direction?: Direction
  responsiveFontSizes?: boolean
  roundedCorners?: boolean
  theme?: string
}

const palette = createPalette({
  action: {
    active: kpSecondaryColors.lightBlue
  },
  background: {
    default: "#000000",
    paper: "#fafafa"
  },
  error: {
    contrastText: "#ffffff",
    main: "#E84B4B"
  },
  mode: "light",
  primary: {
    main: kpPrimaryColors.blue,
    dark: kpPrimaryColors.darkBlue,
    light: kpPrimaryColors.lightBlue,
    contrastText: "#ffffff"
  },
  secondary: {
    main: kpPrimaryColors.lightBlue,
    light: kpSecondaryColors.lightBlue,
    contrastText: "#ffffff"
  },
  lightBlue: {
    main: kpPrimaryColors.lightBlue,
    light: kpSecondaryColors.lightBlue,
    contrastText: "#ffffff"
  },
  white: {
    main: "#ffffff"
  },
  success: {
    contrastText: "#ffffff",
    main: "#4caf50"
  },
  text: {
    primary: kpPrimaryColors.blue,
    secondary: kpSecondaryColors.lightBlue,
    lightBlue: kpPrimaryColors.lightBlue
  },
  warning: {
    contrastText: "#ffffff",
    main: kpPrimaryColors.kpYellow
  },
  warningText: {
    main: kpPrimaryColors.kpYellow
  },
  kpYellowColors: {
    main: kpPrimaryColors.kpYellow
  },
  kpPurpleColors: {
    main: kpSecondaryColors.purple
  },
  kpGreenColors: {
    main: kpSecondaryColors.green
  },
  kpOrangeColors: {
    main: kpSecondaryColors.orange
  },
  kpNeutralColors: {
    main: kpNeutralsColors.lightGray,
    dark: kpNeutralsColors.darkGray,
    light: kpNeutralsColors.contrastGray
  },
  lightGrey: {
    main: kpSecondaryColors.lightGrey
  }
})

export const createTheme = (config: ThemeConfig = {}): Theme => {
  let theme = createMuiTheme({
    direction: "ltr",
    components: {
      MuiLink: {
        defaultProps: {
          underline: "hover"
        }
      },
      MuiAvatar: {
        styleOverrides: {
          fallback: {
            color: palette.white.main,
            height: "75%",
            width: "75%"
          }
        }
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            color: kpPrimaryColors.blue
          }
        }
      },
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: "60px",
            boxShadow: "none",
            "&:hover": {
              boxShadow: "none"
            },
            "&.Mui-disabled": {
              "&.MuiButton-containedLightBlue": {
                color: "rgba(225, 225, 227, 0.3)",
                backgroundColor: "rgba(32, 146, 192, .4)"
              },
              "&.MuiButton-containedWhite": {
                color: "rgba(224, 225, 227, 0.3)",
                backgroundColor: "rgba(224, 225, 227, 0.15)"
              }
            }
          },
          outlined: {
            borderWidth: "2px",
            // borderColor: kpPrimaryColors.darkBlue,
            "&:hover": {
              borderWidth: "2px"
            }
          },
          sizeLarge: {
            padding: "13px 32px",
            fontSize: "1rem"
          },
          sizeMedium: {
            padding: "8px 24px"
          },
          sizeSmall: {
            paddingLeft: "16px",
            paddingRight: "16px",
            fontWeight: 500
          },
          containedSecondary: {
            color: kpPrimaryColors.darkBlue,
            backgroundColor: "rgba(0, 40, 85, 0.1)",
            "&:hover": {
              backgroundColor: "rgba(0, 40, 85, 0.15)"
            }
          }
        },
        variants: [
          {
            props: {variant: "contained", color: "lightBlue"},
            style: {
              backgroundColor: "#2092C0",
              color: "white",
              "&:hover": {
                backgroundColor: "rgba(32, 146, 192, .8)"
              }
            }
          },
          {
            props: {variant: "square"},
            style: {
              borderRadius: 6
            }
          },
          {
            props: {variant: "square", color: "primary"},
            style: {
              backgroundColor: kpPrimaryColors.blue,
              color: "white",
              borderStyle: "solid",
              borderWidth: "1px",
              borderColor: "transparent",
              "&:hover": {
                backgroundColor: kpPrimaryColors.darkBlue
              }
            }
          },
          {
            props: {variant: "square", color: "secondary"},
            style: {
              backgroundColor: "rgba(0, 40, 85, 0.05)",
              "&:hover": {
                backgroundColor: "rgba(0, 40, 85, 0.15)"
              }
            }
          },
          {
            props: {variant: "square", color: "lightBlue"},
            style: {
              backgroundColor: "#2092C0",
              color: "white",
              "&:hover": {
                backgroundColor: "rgba(32, 146, 192, .8)"
              },
              "&.Mui-disabled": {
                backgroundColor: "rgba(32, 146, 192, .8)",
                color: "white"
              }
            }
          },
          {
            props: {variant: "square", color: "error"},
            style: {
              backgroundColor: "rgba(232, 75, 75, 0.15)",
              color: "#E84B4B",
              "&:hover": {
                backgroundColor: "rgba(232, 75, 75, 0.2)"
              }
            }
          },
          {
            props: {variant: "outlinedSquare"},
            style: {
              borderRadius: 6,
              borderStyle: "inset",
              borderWidth: "1px"
            }
          },
          {
            props: {variant: "outlinedSquare", color: "primary"},
            style: {
              borderStyle: "solid",
              borderWidth: "1px",
              borderColor: kpPrimaryColors.blue,
              "&:hover": {
                backgroundColor: kpPrimaryColors.blue,
                color: "white"
              }
            }
          },
          {
            props: {variant: "outlinedSquare", color: "secondary"},
            style: {
              borderStyle: "solid",
              borderWidth: "1px",
              borderColor: "rgba(0, 40, 85, 0.15)",
              backgroundColor: "rgba(0, 40, 85, 0.05)",
              "&:hover": {
                backgroundColor: "rgba(0, 40, 85, 0.15)"
              }
            }
          }
        ]
      },
      MuiButtonBase: {
        styleOverrides: {
          root: {
            whiteSpace: "nowrap"
          }
        }
      },
      MuiIconButton: {
        styleOverrides: {
          sizeLarge: {
            padding: "10px"
          }
        }
      },
      MuiDivider: {
        styleOverrides: {
          root: {
            borderColor: kpSecondaryColors.lightGrey
          }
        }
      },
      MuiBackdrop: {
        styleOverrides: {
          root: {
            backgroundColor: "rgba(0, 40, 85, 0.4)",
            backdropFilter: "blur(4px)"
          },
          invisible: {
            backgroundColor: "transparent",
            backdropFilter: "none"
          }
        }
      },
      MuiDialog: {
        styleOverrides: {
          paper: {
            borderRadius: "32px"
          }
        }
      },
      MuiCardHeader: {
        defaultProps: {
          titleTypographyProps: {
            variant: "h6"
          }
        }
      },
      MuiLinearProgress: {
        styleOverrides: {
          root: {
            borderRadius: 3,
            overflow: "hidden"
          }
        }
      },
      MuiListItemIcon: {
        styleOverrides: {
          root: {
            minWidth: "auto",
            marginRight: "16px"
          }
        }
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            backgroundColor: "#F7F7F7"
          }
        }
      },
      MuiTextField: {
        variants: [
          {
            props: {margin: "normal"},
            style: {
              marginTop: 8,
              marginBottom: 16
            }
          }
        ]
        // styleOverrides: {
        //   root: {
        //     "& .MuiOutlinedInput-notchedOutline": {
        //       borderColor: kpSecondaryColors.lightBlue
        //     }
        //   }
        // }
      },
      MuiInputBase: {
        styleOverrides: {
          input: {
            color: kpPrimaryColors.blue,
            "&::placeholder": {
              fontSize: "inherit",
              opacity: 1,
              color: kpSecondaryColors.lightBlue
            }
          }
        }
      },
      MuiInputLabel: {
        styleOverrides: {
          shrink: {
            background: "transparent",
            padding: "0 4px",
            transform: "translate(10px, -8px) scale(0.75)"
          }
        }
      },
      MuiOutlinedInput: {
        styleOverrides: {
          notchedOutline: {
            "& span": {
              paddingRight: "0 !important"
            }
          }
        }
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            fontSize: ".875rem",
            marginLeft: 0,
            marginTop: ".5rem"
          }
        }
      },
      MuiTableRow: {
        styleOverrides: {
          root: {
            "&:last-of-type > .MuiTableCell-root" : {
              borderBottom: "none"
            }
          }
        }
      },
      MuiSwitch: {
        styleOverrides: {
          root: {
            "& .MuiSwitch-switchBase": {
              "&:hover": {
                backgroundColor: "transparent !important",
                "& .MuiSwitch-thumb": {
                  backgroundColor: "#ffffff",
                  transition: ".3s"
                }
              },
              "&.Mui-checked": {
                "& .MuiSwitch-thumb": {
                  backgroundColor: "#ffffff"
                },
                "& + .MuiSwitch-track": {
                  opacity: 1
                }
              },
              "& .MuiSwitch-thumb": {
                opacity: 1,
                boxShadow: "none",
                backgroundColor: "#f3f3f3"
              },
              "& .MuiTouchRipple-root": {
                display: "none"
              }
            },
            "& .MuiSwitch-track": {
              opacity: 1,
              borderRadius: 30,
              backgroundColor: kpNeutralsColors.lightGray
            }
          }
        },
        variants: [
          {
            props: {size: "small"},
            style: {
              padding: 2,
              margin: 4
            }
          },
          {
            props: {size: "medium"},
            style: {
              padding: 6,
              margin: 6
            }
          }
        ]
      }
    },
    typography: {
      allVariants: {
        color: kpPrimaryColors.blue,
        fontWeight: 500
      },
      button: {
        fontWeight: 700,
        fontSize: "1rem"
      },
      fontFamily:
        "Satoshi, Arial, sans-serif",
      h1: {
        fontWeight: 900,
        fontSize: "3.5rem",
        fontFamily: "Montserrat, sans-serif"
      },
      h2: {
        fontWeight: 900,
        fontSize: "2.5rem",
        fontFamily: "Montserrat, sans-serif"
      },
      h3: {
        fontWeight: 900,
        fontSize: "2.25rem",
        fontFamily: "Montserrat, sans-serif"
      },
      h4: {
        fontWeight: 1000,
        fontSize: "1.75rem",
        fontFamily: "Montserrat, sans-serif"
      },
      h5: {
        fontWeight: 700,
        fontSize: "1.5rem"
      },
      h6: {
        fontWeight: 500,
        fontSize: "1.125rem"
      },
      overline: {
        fontWeight: 600
      }
    },
    palette,
    shadows: lightShadows,
    shape: {
      borderRadius: 12
    },
    zIndex: {
      snackbar: 9999999
    }
  })

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme)
  }

  return theme
}
