import {useLazyQuery} from "@apollo/client"
import GroupAddIcon from "@mui/icons-material/GroupAdd"
import PersonAddIcon from "@mui/icons-material/PersonAddAlt1"
import {Box, Button, Link} from "@mui/material"
import GET_CLASSROOM_IDS from "api/apollo/queries/GET_CLASSROOM_IDS"
import GET_CLASSROOM_STUDENT_DETAILS from "api/apollo/queries/GET_CLASSROOM_STUDENT_DETAILS"
import GET_DISTRICT_STUDENT_DETAILS from "api/apollo/queries/GET_DISTRICT_STUDENT_DETAILS"
import GET_SCHOOL_STUDENT_DETAILS from "api/apollo/queries/GET_SCHOOL_STUDENT_DETAILS"
import AddMultipleStudentsForm from "components/AddStudentsForm/AddMultipleStudentsForm"
import AddSingleStudentForm from "components/AddStudentsForm/AddSingleStudentForm"
import CustomModal from "components/CustomModal"
import DataTable from "components/DataTable"
import {
  DataTableActionButtons,
  DataTableActions,
  DataTableFilters,
  DataTableQueryUpdate,
  DataTableSchema,
  DataTableState
} from "components/DataTable/types.t"
import ExtendTimeForm from "components/ExtendTimeForm"
import AssignInventoryForm from "./components/AssignInventoryForm"
import {LINKS} from "consts/links"
import {
  Classroom,
  DisabilityMultiplierRequestStatus,
  GetClassroomIdsQuery,
  GetClassroomIdsQueryVariables,
  GetClassroomStudentDetailsQuery,
  GetClassroomStudentDetailsQueryVariables,
  GetDistrictStudentDetailsQuery,
  GetDistrictStudentDetailsQueryVariables,
  GetSchoolStudentDetailsQuery,
  GetSchoolStudentDetailsQueryVariables,
  User
} from "generated/graphql"
import {useEffect, useMemo, useState} from "react"
import {Link as RouterLink} from "react-router-dom"
import {DeepPartial} from "redux"
import {useDispatch} from "store"
import {notifyUser} from "store/slices/notifier/notifier"
import {QueryDataType} from "types/typeUtils"
import formatDate from "utils/formatDate"

type ContentTypes =
  "classroomStudents" |
  "schoolStudents" |
  "districtStudents"

type UserTypes = "admin" | "districtAdmin" | "schoolAdmin" | "teacher"
type ModalTypes = "ASSIGN_INVENTORY" | "ADD_MULTIPLE" | "ADD_SINGLE" | "EXTEND_TIME"

interface Props {
  classroomId?: string
  userType?: UserTypes
  schoolId?: string
  districtId?: string
  type: ContentTypes
  showActionButton?: boolean
}

export default function StudentsTable(props: Props) {
  // const {roles} = useSelector(store => store.userSlice)
  const dispatch = useDispatch()

  const [modal, setModal] = useState<ModalTypes | null>(null)
  const [query, setQuery] = useState<DataTableState | null>(null)
  const [studentsData, setStudentsData] = useState<QueryDataType<DeepPartial<User>>>(null)
  const [studentToManage, setStudentToManage] = useState<DeepPartial<User>[] | null>(null)
  const [classroomIdsData, setClassroomIdsData] = useState<
    Partial<Classroom>[]
  >([])

  const [classroomIdsQueryFetch, classroomIdsQuery] = useLazyQuery<
    GetClassroomIdsQuery,
    GetClassroomIdsQueryVariables
  >(GET_CLASSROOM_IDS)

  const [districtStudentsQueryFetch, districtStudentsQuery] = useLazyQuery<
    GetDistrictStudentDetailsQuery,
    GetDistrictStudentDetailsQueryVariables
  >(GET_DISTRICT_STUDENT_DETAILS)

  const [schoolStudentsQueryFetch, schoolStudentsQuery] = useLazyQuery<
    GetSchoolStudentDetailsQuery,
    GetSchoolStudentDetailsQueryVariables
  >(GET_SCHOOL_STUDENT_DETAILS)

  const [classroomStudentsQueryFetch, classroomStudentsQuery] = useLazyQuery<
    GetClassroomStudentDetailsQuery,
    GetClassroomStudentDetailsQueryVariables
  >(GET_CLASSROOM_STUDENT_DETAILS)

  const studentsLoading = useMemo(() => {
    return (
      districtStudentsQuery.loading ||
      schoolStudentsQuery.loading ||
      classroomStudentsQuery.loading ||
      classroomIdsQuery.loading ||
      false
    )
  }, [
    districtStudentsQuery.loading,
    schoolStudentsQuery.loading,
    classroomStudentsQuery.loading,
    classroomIdsQuery.loading
  ])

  const studentsError = useMemo(() => {
    return !studentsData && (
      districtStudentsQuery.error ||
      schoolStudentsQuery.error ||
      classroomStudentsQuery.error ||
      null
    )
  }, [
    studentsData,
    districtStudentsQuery.error,
    schoolStudentsQuery.error,
    classroomStudentsQuery.error
  ])

  const tableSchema: DataTableSchema<User> = useMemo(() => {
    return [
      !props.districtId && {
        type: "checkbox",
        key: data => data._id,
        selectAll: true
      },
      // {
      //   type: "text",
      //   headerText: "Student ID",
      //   headerNoWrap: true,
      //   fieldName: "studentId"
      // },
      props.districtId && {
        type: "text",
        headerText: "School Name",
        headerNoWrap: true,
        fieldName: "school.name"
      },
      {
        type: "text",
        headerText: "Last Name",
        headerNoWrap: true,
        fieldName: "lastName",
        sort: "lastName"
      },
      {
        type: "text",
        headerText: "First Name",
        headerNoWrap: true,
        fieldName: "firstName",
        sort: "firstName"
      },
      {
        type: "text",
        headerText: "Email",
        headerNoWrap: true,
        fieldName: "email"
      },
      {
        type: "custom",
        headerText: "Extended time",
        headerNoWrap: true,
        contentWrap: "nowrap",
        content: data => {
          return data?.disabilityMultiplierRequest?.status === DisabilityMultiplierRequestStatus.Pending ? (
            "Pending Approval"
          ) : (
            data.disabilityMultiplier
          ) ? (
            `${data.disabilityMultiplier * 100 - 100}%`
          ) : (
            "No"
          )
        }
      },
      {
        type: "custom",
        headerText: "Practice tests",
        headerNoWrap: true,
        contentWrap: "nowrap",
        content: data => {
          const filteredVouchers = (data.vouchers || []).filter(i => new Date(i.expirationDate) >= new Date())

          return (
            <Box whiteSpace="pre-line">
              {!filteredVouchers.length ? (
                "No"
              ) : filteredVouchers.map(i => (
                <Box key={i._id} whiteSpace="nowrap">
                  {i.examCode ? (
                    `${i.examCode.toUpperCase()}${i.expirationDate ? ` - ${formatDate(i.expirationDate).replaceAll(".", "/")}` : ""}`
                  ) : ""}
                </Box>
              ))}
            </Box>
          )
        }
      },
      {
        type: "custom",
        headerText: "Courses",
        headerNoWrap: true,
        contentWrap: "nowrap",
        content: data => {
          const courses = data?.assignedCourses || []

          return !!courses ? (
            <Box whiteSpace="pre-line">
              {!courses.length ? (
                "No"
              ) : courses.map(i => (
                <Box key={i.id} whiteSpace="nowrap">
                  {String(i.title.raw)}
                </Box>
              ))}
            </Box>
          ) : null
        }
      },
      {
        type: "custom",
        headerText: "WP Labs",
        headerNoWrap: true,
        contentWrap: "nowrap",
        content: data => {
          const expirationDate = data?.labsLicense?.expirationDate

          return expirationDate && data?.labsLicense?.isActive ? `Yes - ${formatDate(expirationDate).replaceAll(".", "/")}` : "No"
        }
      },
      {
        type: "custom",
        headerText: "",
        contentWrap: "nowrap",
        content: i => {
          return (
            <Link
              to={props.userType === "admin" ? (
                `${LINKS.adminUsers}/${i._id}`
              ) : (
                `${LINKS.studentsListPage}/${i._id}`
              )}
              component={RouterLink}
              underline="none">
              <Button
                variant="outlined"
                color="secondary"
                size="small">
                View More
              </Button>
            </Link>
          )
        }
      }
    ]
  }, [
    props.userType,
    studentsData,
    props.type
  ])

  const handleQueryClassroomIds = (
    value?: string,
    tableState?: DataTableState
  ) => {
    const districtId = props.districtId ||
      tableState?.filters?.find(i => i.id === "districtId")?.value
    const schoolId = props.schoolId ||
      tableState?.filters?.find(i => i.id === "schoolId")?.value

    if (districtId || schoolId) {
      classroomIdsQueryFetch({
        variables: {
          districtId,
          schoolId,
          includeArchived: false,
          search: value
        }
      }).then(res => {
        setClassroomIdsData(res.data?.classrooms?.items || null)
      })
    }
  }

  const tableFilters: DataTableFilters = useMemo(() => {
    return {
      main: [
        !props.classroomId && {
          id: "classroomId",
          type: "select-single",
          label: "Classroom",
          loading: classroomIdsQuery.loading,
          onUpdateOptions: handleQueryClassroomIds,
          options: [
            {label: "All classrooms", value: ""},
            ...classroomIdsData.map(i => ({
              label: i.name || "",
              value: i._id
            }))
          ].filter(Boolean)
        }
      ]
    }
  }, [
    props.classroomId,
    classroomIdsData,
    classroomIdsQuery.loading
  ])

  const tableActionButtons: DataTableActionButtons = useMemo(() => {
    return [
      {
        key: "requestExtendedTime",
        label: "Request extended time",
        variant: "outlined",
        color: "success",
        onClick: (_s, selected) => {
          const list = studentsData?.items.filter(i => (selected || []).includes(i._id)) || []
          setStudentToManage(list)

          if (list.length) {
            setModal("EXTEND_TIME")
          } else {
            dispatch(notifyUser({
              message: "NO_STUDENTS_CHOSEN",
              variant: "info"
            }))
          }
        }
      },
      !props.districtId && {
        key: "assignInventory",
        label: "Assign Inventory",
        variant: "contained",
        disabled: !studentsData?.items?.length,
        onClick: (_state, selected) => {
          const list = studentsData?.items.filter(i => (selected || []).includes(i._id)) || []
          setStudentToManage(list)

          if (list.length) {
            setModal("ASSIGN_INVENTORY")
          } else {
            dispatch(notifyUser({
              message: "NO_STUDENTS_CHOSEN",
              variant: "info"
            }))
          }
        }
      }
    ]
  }, [props.classroomId, studentsData, props.schoolId])

  const tableActions: DataTableActions = useMemo(() => {
    return props.schoolId && {
      options: {
        label: "Create",
        icon: <GroupAddIcon/>,
        variant: "contained",
        isLeftPosition: true
      },
      items: [{
        key: "createMultipleAccounts",
        label: "Create Multiple Accounts",
        icon: <GroupAddIcon/>,
        variant: "outlined",
        color: "success",
        onClick: () => setModal("ADD_MULTIPLE")
      }, {
        key: "createAccount",
        label: "Create New Student Account",
        icon: <PersonAddIcon/>,
        variant: "outlined",
        color: "success",
        onClick: () => setModal("ADD_SINGLE")
      }]
    }
  }, [])

  const handleQuery: DataTableQueryUpdate = (state) => {
    setQuery(state)

    const fetchPolicy = "network-only"
    const {itemsPerPage, page, sort, filters, searchQuery} = state
    const classroomId = props.classroomId || filters?.find(i => i.id === "classroomId")?.value
    const schoolId = props.schoolId || filters?.find(i => i.id === "schoolId")?.value
    const classroomIds =  classroomIdsData?.map(i => i._id) || null

    const commonVars = {
      take: itemsPerPage,
      offset: itemsPerPage * page,
      sortBy: sort?.key,
      order: sort?.order,
      search: searchQuery
    }

    if (classroomId) {
      classroomStudentsQueryFetch({
        fetchPolicy,
        variables: {
          classroomId,
          ...commonVars
        }
      }).then(res => {
        setStudentsData(res.data?.users || null)
      })
    } else if (schoolId) {
      if (props.userType === "admin" || classroomIds?.length) {
        schoolStudentsQueryFetch({
          fetchPolicy,
          variables: {
            schoolId,
            classroomIds: props.userType !== "admin" ? classroomIds : undefined,
            ...commonVars
          }
        }).then(res => {
          setStudentsData(res.data?.users || null)
        })
      }
    } else if (props.districtId) {
      districtStudentsQueryFetch({
        fetchPolicy,
        variables: {
          districtId: props.districtId,
          ...commonVars
        }
      }).then(res => {
        setStudentsData(res.data?.users || null)
      })
    }
  }

  useEffect(() => {
    if (props.schoolId) {
      handleQueryClassroomIds()
    }
  }, [props.schoolId])

  useEffect(() => {
    if (query && props.schoolId && props.userType !== "admin" && !studentsData) {
      handleQuery(query)
    }
  }, [classroomIdsData, query])

  const handleCancelModal = (refetch?: boolean) => {
    setStudentToManage(null)
    setModal(null)
    refetch && handleQuery(query)
  }

  return (
    <Box minHeight="40vh">
      <DataTable
        schema={tableSchema}
        data={studentsData?.items}
        loading={studentsLoading}
        error={!!studentsError}
        emptyDataMessage="No Results Available"
        emptyCondition={() => !studentsData}
        itemsTotalCount={studentsData?.total}
        lastPage={!studentsData?.hasMore}
        onQueryUpdate={handleQuery}
        // defaultSort="lastName"
        search="Search Students"
        filters={tableFilters}
        actionButtons={tableActionButtons}
        actions={tableActions}
      />
      <CustomModal
        open={modal === "ASSIGN_INVENTORY"}
        onClose={() => handleCancelModal()}>
        <AssignInventoryForm
          schoolId={props.schoolId}
          students={studentToManage}
          onAfterSubmit={() => handleCancelModal(true)}
        />
      </CustomModal>
      <CustomModal
        open={modal === "ADD_SINGLE"}
        onClose={() => handleCancelModal()}>
        <AddSingleStudentForm
          classrooms={classroomIdsData}
          onAfterSubmit={() => handleCancelModal(true)}
        />
      </CustomModal>
      <CustomModal
        open={modal === "ADD_MULTIPLE"}
        onClose={() => handleCancelModal()}>
        <AddMultipleStudentsForm
          classrooms={classroomIdsData}
          onAfterSubmit={() => handleCancelModal(true)}
        />
      </CustomModal>
      <CustomModal
        open={modal === "EXTEND_TIME"}
        onClose={() => handleCancelModal()}>
        <ExtendTimeForm
          students={studentToManage}
          onAfterSubmit={() => handleCancelModal(true)}
        />
      </CustomModal>
    </Box>
  )
}
