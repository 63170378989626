import {useTheme} from "@mui/styles"
import MatchTask from "components/tasks/MatchTask"
import ParsonsTask from "components/tasks/ParsonsTask"
import SimpleTask from "components/tasks/SimpleTask"
import {useMemo, useState} from "react"
import {
  Box,
  Button,
  Collapse,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material"
import FileDownloadIcon from "@mui/icons-material/FileDownloadOutlined"
import {PublicTask, SessionAnswer} from "generated/graphql"

interface Props {
  tasks: DeepPartial<PublicTask>[]
  givenAnswers: DeepPartial<SessionAnswer>[]
}

export default function TaskResults({
  tasks,
  givenAnswers
}: Props) {
  const theme = useTheme()

  const [collapsedTasks, setCollapsedTasks] = useState<string[]>([])

  const data = useMemo(() => {
    return {
      columns: [
        {
          content: "#",
          field: "number"
        },
        {
          content: "Task",
          field: "task"
        },
        {
          content: "Status",
          field: "status"
        },
        {
          content: "Result",
          field: "result"
        }
      ],
      bodyData: tasks.map((i, index) => {
        const givenAnswer = givenAnswers.find(answer => {
          return answer?.taskId === i._id
        }) || null

        return {
          number: {
            type: "text",
            content: (
              <Typography variant="body1">
                {index + 1}
              </Typography>
            )
          },
          task: {
            type: "custom",
            task: i,
            givenAnswer
          },
          status: {
            type: "text",
            content: (
              <Typography
                variant="body1"
                color={givenAnswer?.answered ? (givenAnswer?.validated ? "success.main" : "error") : "primary"}>
                {givenAnswer?.answered ? (givenAnswer?.validated ? "Passed" : "Failed") : "Unanswered"}
              </Typography>
            )
          },
          result: {
            type: "view",
            task: i,
            givenAnswer
          }
        }
      })
    }
  }, [givenAnswers, tasks, givenAnswers])

  const handleCollapseTask = (id: string) => {
    setCollapsedTasks(current => {
      const exists = current.find(i => i === id)

      if (exists) {
        return current.filter(i => i !== id)
      } else {
        return [...current, id]
      }
    })
  }

  return (
    <Box p={3} bgcolor="white.main" borderRadius="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h5" fontWeight={900}>
          Task Results
        </Typography>
        <Button
          startIcon={<FileDownloadIcon/>}
          size="small"
          variant="square"
          color="lightBlue">
          Download Stats
        </Button>
      </Box>
      <Divider sx={{my: 3}}/>
      <TableContainer
        component={Box}
        sx={{
          position: "relative",
          border: theme => `1px solid ${theme.palette.divider}`,
          borderRadius: "20px",
          backgroundColor: theme => theme.palette.white.main
        }}>
        <Table sx={{width: "100%"}}>
          <TableHead>
            <TableRow>
              {data.columns.map(i => (
                <TableCell
                  key={i.field}
                  align={i.field === "task" ? "left" : "center"}>
                  <Typography>
                    {i.content}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.bodyData.map((row, rowIndex) => (
              <TableRow key={rowIndex}>
                {data.columns.map((col, colIndex) => {
                  const columnData = row[col.field]
                  const task: Props["tasks"][0] = columnData.task
                  const givenAnswer: Props["givenAnswers"][0] = columnData.givenAnswer
                  const correctAnswer: typeof givenAnswer["correctAnswer"] = givenAnswer?.correctAnswer

                  return (
                    <TableCell
                      key={colIndex}
                      align={col.field === "task" ? "left" : "center"}
                      sx={{
                        verticalAlign: "top"
                      }}>
                      {columnData.type === "text" ? (
                        columnData.content
                      ) : columnData.type === "view" ? (
                        <Typography
                          sx={{
                            textDecoration: "underline",
                            cursor: "pointer"
                          }}
                          color="secondary"
                          onClick={() => handleCollapseTask(task?._id)}>
                          {collapsedTasks.includes(task?._id) ? "Hide" : "View"}
                        </Typography>
                      ) : (
                        <Box>
                          <Typography variant="body1" fontWeight={700}>
                            {task?.displayName} ({task.type})
                          </Typography>
                          <Box>
                            <Collapse in={collapsedTasks.includes(task?._id)}>
                              <Divider sx={{my: 1}}/>
                              {task?.type === "single" ? (
                                <Box display="flex" justifyContent="center" py={2}>
                                  <SimpleTask
                                    key={task._id}
                                    inactive
                                    description={task.description}
                                    code={task.multipleDetails?.codeSnippet?.code}
                                    codeLang={task.multipleDetails?.codeSnippet?.language}
                                    options={task.multipleDetails.options.map(i => ({
                                      id: i._id,
                                      content: i.text
                                    }))}
                                    config={{
                                      fontFamily: theme.typography.fontFamily
                                    }}
                                    answer={correctAnswer?.answers.map(i => i.id)}
                                    givenAnswer={givenAnswer?.answers.map(i => i.id)}
                                  />
                                </Box>
                              ) : task?.type === "multiple" ? (
                                <Box display="flex" justifyContent="center" py={2}>
                                  <SimpleTask
                                    key={task._id}
                                    multiple
                                    inactive
                                    description={task.description}
                                    code={task.multipleDetails?.codeSnippet?.code}
                                    codeLang={task.multipleDetails?.codeSnippet?.language}
                                    options={task.multipleDetails.options.map(i => ({
                                      id: i._id,
                                      content: i.text
                                    }))}
                                    config={{
                                      fontFamily: theme.typography.fontFamily
                                    }}
                                    answer={correctAnswer?.answers.map(i => i.id)}
                                    givenAnswer={givenAnswer?.answers.map(i => i.id)}
                                  />
                                </Box>
                              ) : task?.type === "parsons" ? (
                                <Box display="flex" justifyContent="center" py={2}>
                                  <ParsonsTask
                                    key={task._id}
                                    inactive
                                    description={task.description}
                                    code={task.multipleDetails?.codeSnippet?.code}
                                    codeLang={task.multipleDetails?.codeSnippet?.language}
                                    options={task.answers.map(i => ({
                                      id: i._id,
                                      content: i.content
                                    }))}
                                    config={{
                                      fontFamily: theme.typography.fontFamily
                                    }}
                                    answer={correctAnswer?.answers as Array<{
                                      id: string
                                      position: number
                                    }> | null}
                                    givenAnswer={givenAnswer?.answers}
                                  />
                                </Box>
                              ) : task?.type === "match" ? (
                                <Box display="flex" justifyContent="center" py={2}>
                                  <MatchTask
                                    key={task._id}
                                    inactive
                                    description={task.description}
                                    code={task.multipleDetails?.codeSnippet?.code}
                                    codeLang={task.multipleDetails?.codeSnippet?.language}
                                    questions={task.questions.map(i => ({
                                      id: i._id,
                                      content: i.content,
                                      position: i.position
                                    }))}
                                    options={task.answers.map(i => ({
                                      id: i._id,
                                      content: i.content
                                    }))}
                                    config={{
                                      fontFamily: theme.typography.fontFamily
                                    }}
                                    answer={correctAnswer?.answers as Array<{
                                      id: string
                                      position: number
                                    }> | null}
                                    givenAnswer={givenAnswer?.answers}
                                  />
                                </Box>
                              ) : (
                                <Box py={1}>
                                  <Typography variant="body1" color="error.main">
                                    For this moment we can’t provide answer for practical question.
                                  </Typography>
                                </Box>
                              )}
                            </Collapse>
                          </Box>
                        </Box>
                      )}
                    </TableCell>
                  )
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}
