import {useMutation} from "@apollo/client"
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from "@mui/material"
import CREATE_DISABILITY_MULTIPLIER_REQUESTS from "api/apollo/mutations/CREATE_DISABILITY_MULTIPLIER_REQUESTS"
import {FileInput} from "components/FileInput"
import {Formik} from "formik"
import {
  CreateDisabilityMultiplierRequestsMutation,
  CreateDisabilityMultiplierRequestsMutationVariables,
  User
} from "generated/graphql"
import React, {useMemo} from "react"
import {DeepPartial} from "redux"
import {useDispatch} from "store"
import {handleError} from "store/slices/notifier/notifier"
import * as Yup from "yup"

const additionalTimeOptions = [
  {
    label: "25%",
    value: "1.25"
  },
  {
    label: "50%",
    value: "1.5"
  },
  {
    label: "100%",
    value: "2"
  },
  {
    label: "200%",
    value: "3"
  },
  {
    label: "Half a day",
    value: "16"
  },
  {
    label: "Full day",
    value: "32"
  }
]

interface Props {
  students: DeepPartial<User>[]
  onAfterSubmit: () => void
}

export default function ExtendTimeForm(props: Props) {
  const dispatch = useDispatch()

  const formInitialValues = useMemo(() => {
    return {
      message: "",
      multiplierValue: "",
      proofFileUpload: null
    }
  }, [])

  const [createDisabilityMultiplierRequests, {loading}] = useMutation<
    CreateDisabilityMultiplierRequestsMutation,
    CreateDisabilityMultiplierRequestsMutationVariables
  >(CREATE_DISABILITY_MULTIPLIER_REQUESTS)

  const handleSubmit = (data: typeof formInitialValues) => {
    if (!data || !Object.values(data)?.length) return

    const requests = props.students.map(i => {
      return {
        targetUserId: i._id,
        message: data.message,
        multiplierValue: parseFloat(data.multiplierValue)
      }
    })

    createDisabilityMultiplierRequests({
      variables: {
        proofFileUpload: data.proofFileUpload,
        requests
      },
      context: {
        headers: {
          "Apollo-Require-Preflight": "true"
        }
      }
    }).then(() => {
      props.onAfterSubmit()
    }).catch(err => {
      dispatch(handleError(err))
    })


  }

  return (
    <Formik
      initialValues={formInitialValues}
      validationSchema={Yup.object().shape({
        message: Yup.string().required("Reason request is required"),
        multiplierValue: Yup.string().required("Additional time requested is required"),
        proofFileUpload: Yup.mixed().required("Document proof is required")
      })}
      onSubmit={handleSubmit}>
      {({
        errors,
        values,
        handleBlur,
        handleChange,
        setFieldValue,
        touched,
        dirty,
        handleSubmit
      }) => (
        <form onSubmit={handleSubmit}>
          <Box px={4} py={3} minWidth="40vw">
            <Typography variant="h5" textAlign="center" mb={4}>
              Request extended time
            </Typography>
            <Box mb={2}>
              <Grid container spacing={3} direction="row" maxWidth="860px">
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel error={!!(touched.multiplierValue && errors.multiplierValue)}>
                      Additional time requested
                    </InputLabel>
                    <Select
                      name="multiplierValue"
                      onBlur={handleBlur}
                      label="Select time"
                      error={!!(touched.multiplierValue && errors.multiplierValue)}
                      value={values.multiplierValue}
                      onChange={handleChange}>
                      {additionalTimeOptions.map(i => (
                        <MenuItem key={i.value} value={i.value}>
                          {i.label}
                        </MenuItem>
                      ))}
                    </Select>
                    {Boolean(touched.multiplierValue && errors.multiplierValue) && (
                      <FormHelperText
                        error
                        sx={{
                          ml: "14px",
                          mr: "14px"
                        }}>
                        {touched.multiplierValue && errors.multiplierValue}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="message"
                    value={values.message}
                    error={!!(touched.message && errors.message)}
                    helperText={touched.message && errors.message}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    label="Reason request"
                    fullWidth
                    variant="outlined"
                    multiline
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6" mb={2}>
                    Document proof
                  </Typography>
                  <FileInput
                    error={errors.proofFileUpload as string}
                    onChange={value => {
                      setFieldValue("proofFileUpload", value)
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box display="flex" justifyContent="end" mt={4}>
              <Button
                type="submit"
                variant="contained"
                disabled={!dirty || loading}>
                Submit
              </Button>
            </Box>
          </Box>
        </form>
      )}
    </Formik>
  )
}
