import {useEffect, useState} from "react"
import {
  Box,
  Divider,
  FormControlLabel,
  Grid,
  LinearProgress,
  Paper,
  Switch,
  Typography
} from "@mui/material"
import {useDispatch} from "react-redux"
import {useNavigate} from "react-router"
import ExamsList from "./components/ExamsList"
import {useLazyQuery} from "@apollo/client"
import GET_USER_VOUCHERS from "api/apollo/queries/GET_USER_VOUCHERS"
import {GetUserVouchersQuery, GetUserVouchersQueryVariables, Voucher} from "generated/graphql"
import {orderBy} from "lodash"
import {LINKS} from "consts/links"
import {handleError} from "store/slices/notifier/notifier"

interface Props {
  compact?: boolean
}

export default function StudentVouchersList({
  compact
}: Props) {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [showExpired, setShowExpired] = useState(false)
  const [vouchers, setVouchers] = useState<DeepPartial<Voucher>[]>([])

  const [vouchersQueryFetch, vouchersQuery] = useLazyQuery<
    GetUserVouchersQuery,
    GetUserVouchersQueryVariables
  >(GET_USER_VOUCHERS)

  const handleQueryVouchers = () => {
    vouchersQueryFetch({
      variables: {
        onlyAvailableForUse: !showExpired
      }
    }).then(res => {
      const vouchers = res.data?.me?.vouchers?.filter(i => i.expirationDate)
      let modifyVouchers = []

      if (vouchers) {
        modifyVouchers = orderBy(vouchers, v => new Date(v.expirationDate), "desc")
      }

      setVouchers(modifyVouchers)
    }).catch(err => {
      dispatch(handleError(err))
    })
  }

  useEffect(() => {
    handleQueryVouchers()
  }, [])

  const handleActivate = (examName: string) => {
    navigate(`${LINKS.addExamByVoucher}/${encodeURIComponent(examName)}`)
  }

  const handleStart = (_id: string, examCode: string) => {
    navigate(`${LINKS.selectExamMode}/${_id}-${examCode}`)
  }

  useEffect(() => {
    handleQueryVouchers()
  }, [showExpired])

  const List = () => {
    return (
      <ExamsList
        loading={Boolean(!vouchers.length) && vouchersQuery.loading}
        vouchers={vouchers}
        onStart={handleStart}
        onActivate={handleActivate}
      />
    )
  }

  if (compact) {
    return <List/>
  }

  return (
    <Grid item xs={12} mt={3}>
      <Paper sx={{p: 4}}>
        <Grid
          alignItems="center"
          container
          justifyContent="space-between"
          spacing={3}
          item
          xs={12}>
          <Grid item>
            <Box>
              <FormControlLabel
                control={
                  <Switch
                    checked={showExpired}
                    edge="start"
                    name="direction"
                    onChange={() => setShowExpired(current => !current)}
                  />
                }
                label={
                  <Box>
                    <Typography variant="body1">
                      Expired Switch
                    </Typography>
                    <Typography color="textSecondary" variant="body1">
                      Display expired access
                    </Typography>
                  </Box>
                }
              />
            </Box>
          </Grid>
        </Grid>
        <Box position="relative">
          <Divider sx={{mt: 4, mb: 1}}/>
          {vouchersQuery.loading && (
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                transform: "translateY(-50%)",
                left: 0,
                width: "100%"
              }}>
              <LinearProgress color="primary"/>
            </Box>
          )}
        </Box>
        <List/>
        <Divider sx={{mt: 4}}/>
      </Paper>
    </Grid>
  )
}
